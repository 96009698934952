import React from 'react'

const EmptyList: React.FC = () => {
  return (
    <div 
      data-testid="empty-offers-wishlist-page" 
      id="empty-offers-wishlist-page" 
      className="flex justify-center items-center h-[calc(100svh-54px)]"
    >
      <div className="flex flex-col mb-[48px]">
        <div className='flex justify-center'>
          <img className="w-40" src="/images/empty-rewards.svg" alt="loading-icon" />
        </div>
        <p className="font-medium text-base text-gray-empty text-center">ติดตามสิทธิพิเศษได้เร็วๆนี้</p>
      </div>
    </div>
  )
}

export default EmptyList
