import classNames from 'classnames'
import React from 'react'

interface Props {
  message: string
}

const EmptyOffersSpecialLotus: React.FC<Props> = ({ message }) => {

  return (
    <div
      id='empty-offers-special-lotus-page'
      className={classNames(
        'flex justify-center items-center m-auto',
      )}
    >
      <div id='empty-offers-special-lotus-items' className='flex flex-col items-center'>
        <img id='empty-offers-special-lotus-image' className='w-40' src='/images/empty-rewards.svg' alt='loading-icon' />
        <p id='empty-offers-special-lotus-text' className='text-base text-gray-empty'>{message}</p>
      </div>
    </div>
  )
}

export default EmptyOffersSpecialLotus
