import React, { Fragment, useEffect } from 'react'
import { Card, CardDetailLoading } from '../../../common'
import { OfferDetails } from '../../../services/my-coupon-bff/types'
import { DATE_FORMAT, getDate } from '../../../utils/date'
import { getStatus } from '../../../utils/status'
import { ErrorResponse } from '../../../utils/reponseType'
import Loading from '../specialStore/specialStoreList/Loading'
import classNames from 'classnames'
import EmptyOffersSpecialLotus from './EmptyOffersSpecialLotus'
import { LOTUS } from '../../../utils/constant'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'

export interface Props {
  loading: boolean
  setLoading: (loading: boolean) => void
  name: string
  point: number | undefined
  offerId?: string
  isHideSubMenu: boolean
  onRefresh: Function
  offers: OfferDetails[] | null
  error: ErrorResponse | null
  onSelectOffer: (offerDetails: OfferDetails) => void
  fetchAddFavorites: (id: string, couponSource: string) => void
  fetchDeleteFavorites: (id: string, couponSource: string) => void
}

const OffersSpecialLotusList: React.FC<Props> = ({
  loading,
  point,
  offerId,
  error,
  offers,
  onSelectOffer,
  fetchAddFavorites,
  fetchDeleteFavorites,
}) => {
  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage('iframe:ready', '*')
    }
  }, [])

  const handleOpenOfferDetail = (index: number, event: any) => {
    const isFav = event?.target?.id === `offers-special-lotus-list-card${index}-favorited-icon` ? true : false
    if (isFav) {
      if (offers![index].isFavorited) {
        fetchDeleteFavorites(offers![index].id, 'MAGENTO')
      } else {
        fetchAddFavorites(offers![index].id, 'MAGENTO')
      }
    } else {
      onSelectOffer(offers![index])
      const internalName = offers![index].offerDetails.internalName
      addGAEvent({
        event: 'select_offer_item',
        offer_name: internalName,
      })
    }
  }

  if (loading) {
    return (
      <>
        {offerId ? (
          <CardDetailLoading idElement="offers-special-lotus-card-loading" />
        ) : (
          <div
            id="offers-special-lotus-loading-container"
            data-testid="loading-offers-special-lotus"
          >
            <Loading idElement="offers-special-lotus-loading" />
          </div>
        )}
      </>
    )
  }

  if (error) {
    if (error?.code === 401) {
      return (
        <>
          {offerId ? (
            <CardDetailLoading idElement="offers-special-lotus-card-loading" />
          ) : (
            <Loading idElement="offers-special-lotus-loading" />
          )}
        </>
      )
    } else {
      return <Fragment />
    }
  }

  if (!offers?.length && !loading) {
    return (
      <EmptyOffersSpecialLotus
        message={'ติดตามสิทธิพิเศษได้เร็วๆนี้'}
      />
    )
  }

  return (
    <div
      id="offers-special-lotus-list"
      data-testid="offers-special-lotus-list-component"
      className={classNames(`flex flex-col`, {
        hidden: !offers?.length,
      })}
    >
      {offers?.length &&
        offers.map(
          (
            { id, price, offerDetails, redemptionStartDate, availableInventoryRemaining },
            index
          ) => {
            const expiryDate = getDate(offerDetails.acquisitionEndDate!, DATE_FORMAT)
            const status = getStatus({
              startDate: redemptionStartDate!,
              endDate: expiryDate!,
              point,
              price,
              available: availableInventoryRemaining,
            })

            return (
              <Fragment key={id}>
                <div
                  id={`offers-special-lotus-list-content${index}`}
                  data-testid={`card-component-${id}`}
                  onClick={(event) => handleOpenOfferDetail(index, event)}
                  className={classNames({
                    'pb-[16px]': index === offers.length - 1,
                  })}
                >
                  <Card
                    idElement={`offers-special-lotus-list-card${index}`}
                    key={id}
                    company={LOTUS}
                    title={offerDetails.title}
                    fontTitle="text-[16px] leading-6"
                    date={expiryDate}
                    status={status}
                    image={offerDetails.media.url}
                    isFavorited={offers![index].isFavorited}
                    loading={loading}
                    startDate={getDate(redemptionStartDate!, 'DD MMM BBBB, HH:mm')}
                  />
                </div>
              </Fragment>
            )
          }
        )}
    </div>
  )
}

export default OffersSpecialLotusList
