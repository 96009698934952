import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import {
  Error as ErrorPage,
  FilterOfferSpecialStore,
  Footer,
  GeneralError as FullErrorPage,
  SearchOfferSpecialStore,
  SortingOfferSpecialStore,
  Loading,
  Alert,
} from '../../../common'
import {
  addFavorites,
  deleteFavorites,
  getBZBCouponDetails,
  getCampaignCategory,
  redeemingOffer,
} from '../../../services/my-coupon-bff'
import { ErrorResponse, HttpErrorResponse } from '../../../utils/reponseType'
import { CampaignCategory, CouponsBzbDetails } from '../../../services/my-coupon-bff/types'
import SpecialStoreList from './specialStoreList'
import SpecialStoreDetail from './specialStoreDetail'
import {
  getPartnerOffers,
  getPartnerOffersDetails,
  getProfile,
} from '../../../services/my-coupon-bff'
import { PartnerOfferDetails, PartnerOffers, Profile } from '../../../services/my-coupon-bff/types'
import { getParam } from '../../../utils/params'
import { Transition } from 'react-transition-group'
import badRequestImage from '../../../assets/images/bad-request.svg'
import { getLocalStorageString, setLocalStorage, removeLocalStorage } from '../../../utils/storage'
import { ModalConfirmRedeem, ModalError, ModalSuccess } from './specialStoreDetail/Modal'
import { BZB_PROJECT_TYPE, OFFERS_BZB_TYPE } from '../../../utils/constant'
import { formatMinutesToHours } from '../../../utils/date'
import Search from './search'
import { getBZBToken, getOfferType, requestBZBToken, TOKEN_RECEIVED_MESSAGE, transformToCoupon } from '../../functions'
import { useParams } from 'react-router-dom'
import { ERROR_BZB_NORMAL, ERROR_GENERAL, ERROR_BZB_CODE } from '../../../utils/error'
import { waitTokenChange } from '../../../utils'

export interface Props {
  isRefresh: boolean
  onRefresh: Function
  setHideFilter: Function
  isHideFilter: boolean
  isShowSpecialStoreDetails: boolean
  isShowOnlyList?: boolean
}

let specialStoreResult: PartnerOffers[] = [] // Cache variable for storing specialStore items
let selectedSpecialStoreItemFavoriteStatus: boolean | undefined = undefined // Used for handle favorite icon error in detail

const OffersSpecialStore: React.FC<Props> = ({
  setHideFilter,
  isHideFilter,
  isRefresh,
  onRefresh,
  isShowSpecialStoreDetails,
  isShowOnlyList
}) => {
  const { offerId } = useParams()
  const nodeRef = useRef(null)
  const [durationTransition, setDurationTransition] = useState<number>(0)
  const [isShowingSelectedOfferDetails, setIsShowingSelectedOfferDetails] = useState<boolean>(isShowSpecialStoreDetails)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [error, setError] = useState<ErrorResponse>()
  const [lastFavoriteToggleData, setLastFavoriteToggleData] = useState<{ id: number | undefined }>({ id: undefined })

  const [menuElement, setMenuElement] = useState<HTMLElement>()
  const [searchElement, setSearchElement] = useState<HTMLElement>()
  const [filterElement, setFilterElement] = useState<HTMLElement>()
  const [sortingElement, setSortingElement] = useState<HTMLElement>()
  const [listElement, setListElement] = useState<HTMLElement>()
  const [footerElement, setFooterElement] = useState<HTMLElement>()

  const [category, setCategory] = useState<CampaignCategory[]>([])
  const [activeCategory, setActiveCategory] = useState<number>(0)

  const [specialStore, setSpecialStore] = useState<PartnerOffers[]>([])
  const [originalSpecialStore, setOriginalSpecialStore] = useState<PartnerOffers[]>([])

  // Offer details
  const [specialStoreItem, setSpecialStoreItem] = useState<PartnerOfferDetails | undefined>()
  const [specialStoreItemId, setSpecialStoreItemId] = useState<number>()
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false)
  const [profile, setProfile] = useState<Profile>()
  const [isErrorProfile, setIsErrorProfile] = useState<boolean>(false)
  const [isShowingSearchSection, setIsShowingSearchSection] = useState<boolean>(false)
  const [isShowingSearchDetailSection, setIsShowingSearchDetailSection] = useState<boolean>(false)
  const [isShowModalRedeem, setIsShowModalRedeem] = useState<boolean>(false)
  const [isShowModalRedeemSuccess, setIsShowModalRedeemSuccess] = useState<boolean>(false)
  const [redeemErrorCode, setRedeemErrorCode] = useState<number | undefined>()
  const [redeemError, setRedeemError] = useState<string>()
  const [isLoadingRedeemOffers, setIsLoadingRedeemOffers] = useState(false)
  const [offerType, setOfferType] = useState<OFFERS_BZB_TYPE>(OFFERS_BZB_TYPE.NON_COUNTDOWN)
  const [postMessageCounter, setPostMessageCounter] = useState<number>(0)
  const [isDisableFooter, setIsDisableFooter] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<string>()
  const [isFavorited, setIsFavorited] = useState<boolean | undefined>(false)

  // Search list for favorites 
  const [idItemSearchListFavorites, setIdItemSearchListFavorites] = useState<number | undefined>(undefined)
  const [isAddFavoritesForSearchList, setIsAddFavoritesForSearchList] = useState<boolean>(false)

  // Coupons details
  const [couponsDetails, setCouponsDetails] = useState<CouponsBzbDetails>()

  // favorites
  const [successAddFavorites, setSuccessAddFavorites] = useState<string>('')
  const [successDelFavorites, setSuccessDelFavorites] = useState<string>('')
  const [errorAddFavorites, setErrorAddFavorites] = useState<string>('')
  const [errorDelFavorites, setErrorDelFavorites] = useState<string>('')

  const fetchProfile = useCallback(async () => {
    try {
      setError(undefined)
      setIsError(false)
      setIsErrorProfile(false)
      setIsLoading(true)
      const response = await getProfile()

      if (response) {
        const result = response as Profile

        setProfile(result)
        setIsLoading(false)
      }
    } catch (e) {
      const error = e as ErrorResponse
      setIsError(true)
      setError(error)
      setIsLoading(false)
      setHideFilter(true)
      setIsErrorProfile(true)
    }
  }, [setHideFilter])

  const fetchCategoryList = useCallback(async () => {
    const typeAll = [{ id: 0, name_th: 'ทั้งหมด', image_url: '/icons/filter-all-icon.svg' }]

    setIsLoading(true)
    setCategory([])
    try {
      const token: string = getParam('token') || localStorage?.getItem('token') || ''
      const response: CampaignCategory[] = await getCampaignCategory(token)

      setCategory(response.length === 0 ? typeAll : response)
      setIsLoading(false)
    } catch (e) {
      setCategory(typeAll)
      setIsLoading(false)
    }
  }, [])

  const fetchSpecialStoreList = useCallback(async () => {
    setError(undefined)
    setIsError(false)
    setIsLoading(true)
    setSpecialStore([])
    try {
      const token: string = getParam('token') || localStorage?.getItem('token') || ''
      const bzbToken = getBZBToken()
      const response = await getPartnerOffers(token, bzbToken, true)

      if (response) {
        setSpecialStore(response as PartnerOffers[])
        setOriginalSpecialStore(response as PartnerOffers[])
        specialStoreResult = (response as PartnerOffers[])
      }
      setIsLoading(false)
    } catch (e) {
      setError(e as ErrorResponse)
      setIsError(true)
      setIsLoading(false)
    }
  }, [])

  const fetchSpecialStoreDetails = useCallback(
    async (specialStoreItemId: number) => {
      try {
        setError(undefined)
        setIsError(false)
        setIsLoadingDetails(true)
        setIsShowingSelectedOfferDetails(true)
        setDurationTransition(getLocalStorageString('special-store-id-from-param') ? 0 : 500)

        const token: string = getParam('token') || localStorage?.getItem('token') || ''
        const bzbUerId: string = localStorage?.getItem('bzb_user_id') || ''
        const bzbToken = getBZBToken()

        const response = await getPartnerOffersDetails(token, specialStoreItemId, bzbToken, bzbUerId)
        if (response) {
          const data = response as PartnerOfferDetails
          if (data?.TokenExpired) {
            if (postMessageCounter !== 5) {
              const expiredToken = localStorage.getItem('bzb_user_token') 
              requestBZBToken(postMessageCounter, setPostMessageCounter)
              await waitTokenChange(expiredToken as any)
              fetchSpecialStoreDetails(specialStoreItemId)
            }
          } else {
            const type =  getOfferType(data.Type, data.MinutesValidAfterUsed)

            setSpecialStoreItem(data)
            setOfferType(type)

            setIsLoadingDetails(false)
            setIsError(false)
            setError(undefined)
          }
        } else {
          setIsLoadingDetails(false)
          setIsError(false)
          setError(undefined)
        }
      } catch (e) {
        setError(e as ErrorResponse)
        setIsError(true)
        setIsLoadingDetails(false)
        setHideFilter(true)
      }
    },
    [setHideFilter, postMessageCounter]
  )

  const fetchCouponById = useCallback(
    async (id: string) => {
      try {
        const bzbToken = getLocalStorageString('bzb_user_token')
        const response = await getBZBCouponDetails(id, bzbToken)
        
        if (response) {
          const result = transformToCoupon(response as CouponsBzbDetails, offerType)
   
          setCouponsDetails(response as CouponsBzbDetails)
          setLocalStorage('successMessage', 'รับสิทธิ์สำเร็จ')
          setLocalStorage('couponDetail', result || {})
          
          return result
        }
      } catch (e) {
        console.log({ e })
      }
    },
    [offerType]
  )

  const redeemOffers = useCallback(
    async (id: number) => {
      try {
        setRedeemErrorCode(undefined)
        setIsShowModalRedeem(false)
        setIsShowModalRedeemSuccess(false)
        setIsLoadingRedeemOffers(true)
        setRedeemError(undefined)

        const bzbToken = getLocalStorageString('bzb_user_token')
        const response = await redeemingOffer(id.toString(), 'bzb', bzbToken) as CouponsBzbDetails

        if (response) {
          setIsLoadingRedeemOffers(false)

          if (offerType === OFFERS_BZB_TYPE.CHARITY) {
            setIsShowModalRedeemSuccess(true)
          } else if (
            offerType === OFFERS_BZB_TYPE.COUNTDOWN ||
            offerType === OFFERS_BZB_TYPE.NON_COUNTDOWN
          ) {
            const result = await fetchCouponById(response.RedeemKey)

            // go to coupons details
            if(result && window){
              window.parent.postMessage('back-to-coupons-list', '*')
              window.history.replaceState('detail', '', '/coupons' + window.location.search)
              window.location.reload()
            }
          } else if (offerType === OFFERS_BZB_TYPE.LUCKY_DRAW) {
            setCouponsDetails(response)
            setIsShowModalRedeemSuccess(true)
          }
        }
      } catch (e: any) {
        const errorCode = e?.code

        if (errorCode) {
          setRedeemErrorCode(errorCode)
          if (errorCode === 500) {
            setRedeemError(ERROR_GENERAL.DEPENDENCY_ERROR)
            setTimeout(() => setRedeemError(undefined), 3000)
          } 
          if(errorCode !== ERROR_GENERAL.TOKEN_EXPIRED) {
            setIsShowModalRedeem(true)
            setIsLoadingRedeemOffers(false)
          } else {
            if (postMessageCounter !== 5) {
              requestBZBToken(postMessageCounter, setPostMessageCounter)
              setRedeemError(ERROR_GENERAL.DEPENDENCY_ERROR)
            } else {
              setIsShowModalRedeem(true)
              setIsLoadingRedeemOffers(false)
              setRedeemErrorCode(ERROR_GENERAL.TOKEN_EXPIRED)
              setRedeemError(ERROR_GENERAL.DEPENDENCY_ERROR)
            }
          }
        } 

      }
    },
    [offerType, fetchCouponById, postMessageCounter]
  )

  const redeemOffersTypeWebView = (website: string) => {
    const bzbToken = getLocalStorageString('bzb_user_token')
    const returnUrl = `${process.env.REACT_APP_LIFF_LINE_WEB_LOYALTY_URL}?menu=my-coupon&path=offers&type=special-store&offer-id=${specialStoreItemId}`
    const url = `${website}${website.includes('?') ? '&' : '?'}token=${bzbToken}&return_url=${encodeURIComponent(returnUrl)}&locale=1054&appName=tesco`
    window.open(url, 'blank')
  }

  const onScroll = () => {
    let menuTop = 0 - listElement!.scrollTop
    let searchTop = menuElement!.clientHeight - listElement!.scrollTop
    let filterTop = menuElement!.clientHeight + searchElement!.clientHeight - listElement!.scrollTop
    let sortingTop =
      menuElement!.clientHeight +
      searchElement!.clientHeight +
      filterElement!.clientHeight -
      listElement!.scrollTop
    let sumMarginTop =
      menuElement!.clientHeight +
      searchElement!.clientHeight +
      filterElement!.clientHeight +
      sortingElement!.clientHeight -
      listElement!.scrollTop

    const maximumSumMarginTop =
      menuElement!.clientHeight +
      searchElement!.clientHeight +
      filterElement!.clientHeight +
      sortingElement!.clientHeight
    if (sumMarginTop <= 0) sumMarginTop = 0
    else if (sumMarginTop >= maximumSumMarginTop) sumMarginTop = maximumSumMarginTop

    menuElement!.style.top = `${menuTop}px`
    searchElement!.style.top = `${searchTop}px`
    filterElement!.style.top = `${filterTop}px`
    sortingElement!.style.top = `${sortingTop}px`
    listElement!.style.height = `calc(100% - ${sumMarginTop + footerElement!.clientHeight}px)`
    listElement!.style.marginTop = `${sumMarginTop}px`
  }

  const getStylesSearch = () => {
    const defaultTop = 40
    let sum = 0
    if (menuElement?.clientHeight) sum = menuElement.clientHeight
    return { top: `${sum > 0 ? sum : defaultTop}px` }
  }

  const getStylesFilter = () => {
    const defaultTop = 109
    let sum = 0
    if (menuElement?.clientHeight && searchElement?.clientHeight) {
      sum = menuElement.clientHeight + searchElement.clientHeight
    }
    return { top: `${sum > 0 ? sum : defaultTop}px` }
  }

  const getStylesSorting = () => {
    const defaultTop = 199
    let sum = 0
    if (menuElement?.clientHeight && searchElement?.clientHeight && filterElement?.clientHeight) {
      sum = menuElement.clientHeight + searchElement.clientHeight + filterElement.clientHeight
    }
    return { top: `${sum > 0 ? sum : defaultTop}px` }
  }

  const getStylesList = () => {
    const defaultTop = 199
    let sum = 0
    let sumFooter = 52
    if (
      menuElement?.clientHeight &&
      searchElement?.clientHeight &&
      filterElement?.clientHeight &&
      footerElement?.clientHeight
    ) {
      sum = menuElement.clientHeight + searchElement.clientHeight + filterElement.clientHeight
      sumFooter = footerElement.clientHeight
    }
    return {
      height: `calc(100% - ${(sum > 0 ? sum : defaultTop) + sumFooter}px)`,
      marginTop: `${sum > 0 ? sum : defaultTop}px`,
    }
  }

  const refreshPage = useCallback(async () => {
    setMenuElement(document.getElementById('offers-menu')!)
    setSearchElement(document.getElementById('offers-special-store-search')!)
    setFilterElement(document.getElementById('offers-special-store-filter')!)
    setSortingElement(document.getElementById('offers-special-store-sorting')!)
    setListElement(document.getElementById('offers-special-store-list')!)
    setFooterElement(document.getElementById('offers-footer')!)

    await fetchProfile()
    await fetchCategoryList()
    await fetchSpecialStoreList()
  }, [fetchCategoryList, fetchSpecialStoreList, fetchProfile])

  const handleParentEvent = useCallback(
    (event: MessageEvent) => {
      if (event.data === 'iframe:navigate_back') {
        if (isShowModalRedeemSuccess) setIsShowModalRedeemSuccess(false)
        if (isShowModalRedeem) setIsShowModalRedeem(false)

        if (isShowingSearchDetailSection) {
          // Back to search page from offer detail
          setSpecialStoreItemId(undefined)
          setIsShowingSelectedOfferDetails(false)
          setIsShowingSearchDetailSection(false)
          selectedSpecialStoreItemFavoriteStatus = undefined
        } else if (isShowingSearchSection) {
          // Back to offer list from search page
          if (window) window.parent.postMessage('selected-offer-special-list', '*')

          setIsShowingSearchSection(false)
          removeLocalStorage('search')
          refreshPage()
        } else if (specialStoreItemId) {
          // Back from offer details to list
          setSpecialStoreItemId(undefined)
          setIsShowingSelectedOfferDetails(false)
          setTimeout(() => setDurationTransition(0), 500)
          localStorage?.setItem('tab', 'offer-special-list')
          setIsDisableFooter(false)
          setIsLoadingRedeemOffers(false)

          if (getLocalStorageString('special-store-id-from-param')) {
            refreshPage()
            removeLocalStorage('special-store-id-from-param')
          }
        }
      }
      else if (typeof event.data === 'string') {
        if (event.data.match(TOKEN_RECEIVED_MESSAGE)) {
          const token = JSON.parse(event.data)?.token
          const userId = JSON.parse(event.data)?.userId
          if (token && userId) {
            localStorage.setItem('bzb_user_token', token)
            localStorage.setItem('bzb_user_id', userId)

            if(redeemErrorCode === ERROR_GENERAL.TOKEN_EXPIRED && specialStoreItem) {
              redeemOffers(specialStoreItem?.ID)
            }
          } else {
            requestBZBToken(postMessageCounter, setPostMessageCounter)
          }
        }
      }
    },
    [
      specialStoreItemId,
      setSpecialStoreItemId,
      isShowingSearchDetailSection,
      isShowingSearchSection,
      refreshPage,
      isShowModalRedeem,
      isShowModalRedeemSuccess,
      postMessageCounter,
      redeemErrorCode,
      redeemOffers,
      specialStoreItem,
    ]
  )

  const updateOffersSpecialStoreForFavorites = useCallback((isAddFav: boolean, id: number) => {
    if (originalSpecialStore && originalSpecialStore.length > 0) {
      const updatedOriginalSpecialStore = originalSpecialStore?.map(item => ({
        ...item,
        isFavorited: item.ID === id ? isAddFav : item.isFavorited
      }))
      setOriginalSpecialStore(updatedOriginalSpecialStore)
    }
    if (specialStore && specialStore.length > 0) {
      const updatedSpecialStore = specialStore?.map(item => ({
        ...item,
        isFavorited: item.ID === id ? isAddFav : item.isFavorited
      }))
      setSpecialStore(updatedSpecialStore)
    }
  }, [originalSpecialStore, specialStore])

  const hideAllMessageFavorites = () => {
    setSuccessAddFavorites('')
    setErrorAddFavorites('')
    setSuccessDelFavorites('')
    setErrorDelFavorites('')
  }

  const fetchAddFavorites = useCallback(async (id: string, couponSource: string, isSearchSection: boolean) => {
    const tab = getLocalStorageString('tab')

    updateOffersSpecialStoreForFavorites(true, parseInt(id))
    hideAllMessageFavorites()
    setSuccessAddFavorites('บันทึกไปยังรายการที่ถูกใจแล้ว<span class="absolute right-0">ดู</span>')

    // for search section
    if (tab === 'offer-special-list' && isSearchSection) {
      setIdItemSearchListFavorites(parseInt(id))
      setIsAddFavoritesForSearchList(true)
    }

    if (id) {
      setLastFavoriteToggleData({ id: parseInt(id) })
    }

    try {
      setIsFavorited(true)
      await addFavorites(id, couponSource)
      selectedSpecialStoreItemFavoriteStatus = true
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    } catch (e) {
      if (tab === 'offer-special-list') {
        updateOffersSpecialStoreForFavorites(false, parseInt(id))

        // for search section
        if (isSearchSection) setIsAddFavoritesForSearchList(false)
      } else {
        updateOffersSpecialStoreForFavorites(selectedSpecialStoreItemFavoriteStatus ?? false, parseInt(id))
      }

      setIsFavorited(selectedSpecialStoreItemFavoriteStatus ?? false)
      if (id) {
        setLastFavoriteToggleData({ id: parseInt(id) })
      }
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      const error = e as ErrorResponse
      setErrorAddFavorites(`ขออภัย เกิดข้อผิดพลาดในระบบ (${error.code})<br/>เรากำลังแก้ปัญหา และจะกลับมาใช้งานได้เร็วๆนี้`)
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    }
  }, [updateOffersSpecialStoreForFavorites])

  const fetchDeleteFavorites = useCallback(async (id: string, couponSource: string, isSearchSection: boolean) => {
    const tab = getLocalStorageString('tab')
    
    updateOffersSpecialStoreForFavorites(false, parseInt(id))
    hideAllMessageFavorites()
    setSuccessDelFavorites('ลบออกจากรายการที่ถูกใจแล้ว')
    setIsFavorited(false)

    // for search section
    if (tab === 'offer-special-list' && isSearchSection) {
      setIdItemSearchListFavorites(parseInt(id))
      setIsAddFavoritesForSearchList(false)
    }

    if (id) {
      setLastFavoriteToggleData({ id: parseInt(id) })
    }
    
    try {
      await deleteFavorites(id, couponSource)
      selectedSpecialStoreItemFavoriteStatus = false
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    } catch (e) {
      if (tab === 'offer-special-list') {
        updateOffersSpecialStoreForFavorites(true, parseInt(id))

        // for search section
        if (isSearchSection) setIsAddFavoritesForSearchList(true)
      } else {
        updateOffersSpecialStoreForFavorites(selectedSpecialStoreItemFavoriteStatus ?? true, parseInt(id))
      }

      setIsFavorited(selectedSpecialStoreItemFavoriteStatus ?? true)
      if (id) {
        setLastFavoriteToggleData({ id: parseInt(id) })
      }
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      const error = e as ErrorResponse
      setErrorDelFavorites(`ขออภัย เกิดข้อผิดพลาดในระบบ (${error.code})<br/>เรากำลังแก้ปัญหา และจะกลับมาใช้งานได้เร็วๆนี้`)
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    }
  }, [updateOffersSpecialStoreForFavorites])

  const handleOnSelectOfferDetail = useCallback(
    async (id: number, isFavorited: boolean | undefined, index: number | undefined, isSearchSection: boolean, event: any) => {
     
      let _isFavorited = isFavorited

      // opened directly from URL, some of the params will be undefined
      // which we need to handle the case separately
      if (isFavorited === undefined) {
        try {
          const token: string = getParam('token') || localStorage?.getItem('token') || ''
          const bzbToken = getBZBToken()
          const response = await getPartnerOffers(token, bzbToken, true)

          specialStoreResult = (response as PartnerOffers[])

          const offer = (response as PartnerOffers[]).find((offer) => offer.ID === id)

          if (offer) _isFavorited = offer.isFavorited
        } catch (e) {}
      }

      const specialStoreItem = specialStoreResult.find((offer) => offer.ID === id)
      
      if (specialStoreItem) selectedSpecialStoreItemFavoriteStatus = specialStoreItem?.isFavorited              

      setCurrentId(id.toString())

      const isFav = event?.target?.id === `offers-special-store-list-card${index}-favorited-icon` ? true : false
      if (isFav) {
        if (_isFavorited) {
          await fetchDeleteFavorites(id.toString(), 'BZB', isSearchSection)
          setIsFavorited(false)
        } else {
          await fetchAddFavorites(id.toString(), 'BZB', isSearchSection)
          setIsFavorited(true)
        }
      } else {
        fetchProfile()
        setIsFavorited(_isFavorited)
        fetchSpecialStoreDetails(id)
    
        setSpecialStoreItem(undefined)
        localStorage?.setItem('tab', 'offer-special-detail')
        setLocalStorage('special-store-id', id)
        setSpecialStoreItemId(id)
      }
    },
    [fetchProfile, fetchSpecialStoreDetails, fetchAddFavorites, fetchDeleteFavorites]
  )

  const goToOfferList = () => {
    setSpecialStoreItemId(undefined)
    setIsShowingSelectedOfferDetails(false)
    setTimeout(() => setDurationTransition(0), 500)
    localStorage?.setItem('tab', 'offer-special-list')
  }

  const getProjectName = (message?: string): string | null => {
    try {
      if (!message) return null
  
      const parsedMessage = JSON.parse(message)
      return parsedMessage?.Project || null
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleParentEvent)
    }

    return () => {
      window.removeEventListener('message', handleParentEvent)
    }
  }, [specialStoreItemId, handleParentEvent])

  useEffect(() => {
    if (activeCategory) {
      const filteredStores = originalSpecialStore.filter(
        (data) => data.CategoryId === activeCategory
      )
      setSpecialStore(filteredStores.length ? filteredStores : [])
    } else {
      setSpecialStore(originalSpecialStore)
    }
  }, [activeCategory, originalSpecialStore])

  useEffect(() => {
    if (isRefresh) {
      // for offer special store detail from param
      if (offerId && getLocalStorageString('special-store-id-from-param')) {
        handleOnSelectOfferDetail(parseInt(offerId), undefined, undefined, false, undefined)
      } else {
        setHideFilter(false)
        refreshPage()
        onRefresh(false)
        setSpecialStoreItemId(undefined)
      }
    }
  }, [refreshPage, isRefresh, onRefresh, setHideFilter])

  useEffect(() => {
    if (
      redeemErrorCode === ERROR_GENERAL.TOKEN_EXPIRED && 
      specialStoreItem &&
      postMessageCounter !== 5
    ) {
      redeemOffers(specialStoreItem?.ID)
    } else if (postMessageCounter === 5 && isLoadingRedeemOffers) {
      setIsShowModalRedeem(true)
      setIsLoadingRedeemOffers(false)
      setRedeemErrorCode(ERROR_GENERAL.TOKEN_EXPIRED)
      setRedeemError(ERROR_GENERAL.DEPENDENCY_ERROR)
    }

  }, [postMessageCounter, redeemErrorCode, specialStoreItem, redeemOffers, isLoadingRedeemOffers])

  return (
    <div id="offers-special-store-page">
      {isLoadingRedeemOffers && (
        <div className='mt-[222px]'>
          <Loading idElement="offer-details-loading" isShow={isLoadingRedeemOffers} />
        </div>
      )}
      {Object.values(ERROR_BZB_CODE).includes(redeemErrorCode as ERROR_BZB_CODE) ? (
        <ModalError 
          error={redeemErrorCode} 
          isShowModal={isShowModalRedeem}
          onClick={() => {
            if(Object.values(ERROR_BZB_NORMAL).includes(redeemErrorCode as ERROR_BZB_CODE)) {
              setIsShowModalRedeem(false)
              setRedeemErrorCode(undefined)
              refreshPage()
              goToOfferList()
            } else {
              setIsDisableFooter(true)
              setRedeemErrorCode(undefined)
              setIsShowModalRedeem(false)
            }
          }}
          idElement='modal-error-redeem'
          disabled={false}
        />
      )
      : (
        <ModalConfirmRedeem
          alertError={redeemError}
          idElement="modal-confirm-redeem"
          offerType={offerType}
          countdownTime={formatMinutesToHours(specialStoreItem?.MinutesValidAfterUsed)}
          coin={specialStoreItem?.PointPerUnit || 0}
          isShowModal={isShowModalRedeem}
          setIsShowModal={setIsShowModalRedeem}
          disabled={isLoadingRedeemOffers}
          onClick={() => {
            const projectName = getProjectName(specialStoreItem?.CustomFacebookMessage)

            setPostMessageCounter(0)

            if(!navigator.onLine) {
              setRedeemError(ERROR_GENERAL.NO_INTERNET)
              setTimeout(() => setRedeemError(undefined), 3000)
            } else if (specialStoreItem?.ID && offerType !== OFFERS_BZB_TYPE.WEB_VIEW) {
              redeemOffers(specialStoreItem?.ID)
            } else if (projectName === BZB_PROJECT_TYPE.MEPOINT && offerType === OFFERS_BZB_TYPE.WEB_VIEW) {
              const url = specialStoreItem?.MerchantUrl
              if (url) {
                redeemOffersTypeWebView(url)
              } else {
                setRedeemError(ERROR_GENERAL.DEPENDENCY_ERROR)
                setTimeout(() => setRedeemError(undefined), 3000)
              }
            }else if (specialStoreItem?.Website && offerType === OFFERS_BZB_TYPE.WEB_VIEW) {
              redeemOffersTypeWebView(specialStoreItem?.Website)
            }
          }}
        />
      )}

      <ModalSuccess
        offerType={offerType}
        isShowModal={isShowModalRedeemSuccess}
        disabled={isLoadingRedeemOffers}
        idElement="modal-redeem-success"
        couponsDetails={couponsDetails}
        onClick={() => {
          if (getLocalStorageString('search') && getLocalStorageString('search') !== 'false') {
            if (window) window.parent.postMessage('selected-offer-special-list', '*')
            setSpecialStoreItemId(undefined)
            setIsShowingSelectedOfferDetails(false)
            setIsShowingSearchDetailSection(false)
            setIsShowingSearchSection(false)
            removeLocalStorage('search')
          }
          onRefresh(true)
          setIsShowModalRedeemSuccess(false)
          setSpecialStoreItemId(undefined)
          setIsShowingSelectedOfferDetails(false)
          setTimeout(() => setDurationTransition(0), 500)
          localStorage?.setItem('tab', 'offer-special-list')

          if (localStorage.getItem("back-action-btn") === "offer-wishlist") {
            window.location.href = `/offers/?type=wishlist`
          }
        }}
      />
      <div className={classNames('', { hidden: !isErrorProfile })}>
        <FullErrorPage
          idElement="offers-special-store-general-error"
          title={'ขออภัย มีบางอย่างผิดพลาด'}
          detail={'กรุณาลองอีกครั้ง'}
          image={badRequestImage}
          onClick={() => {
            setHideFilter(false)
            if (specialStoreItemId) {
              fetchProfile()
              fetchSpecialStoreDetails(specialStoreItemId)
            } else {
              onRefresh(true)
            }
          }}
        />
      </div>
      <div
        className={classNames(
          'flex-1 flex justify-center item-center h-[100svh] overflow-y-hidden',
          { 'pb-[125px]': getLocalStorageString('tab') === 'offer-special-detail' },
          { hidden: !isError }
        )}
      >
        <ErrorPage
          idElement="offers-special-store-error"
          onClick={() => {
            if (specialStoreItemId) {
              setHideFilter(false)
              fetchProfile()
              fetchSpecialStoreDetails(specialStoreItemId)
            } else {
              refreshPage()
            }
          }}
          status={error ? error.code : 500}
          refCode={error?.refCode || ''}
        />
      </div>

      <div
        id="offers-special-store"
        className={classNames('flex flex-col h-full fixed overflow-hidden', {
          hidden: isHideFilter || isError || isErrorProfile,
        })}
      >
        {
          isShowOnlyList ? "" : 
          <div
            id="offers-special-store-search"
            className={classNames('fixed w-full')}
            style={getStylesSearch()}
          >
            <SearchOfferSpecialStore
              isLoading={isLoading}
              onClick={() => {
                setIsShowingSearchSection(true)
                setLocalStorage('search', true)
                setDurationTransition(500)
              }}
            />
          </div>
        }
        {
          isShowOnlyList ? '' :
          <div
            id="offers-special-store-filter"
            className={classNames(
              'flex flex-col fixed right-0 left-0 overflow-x-auto overflow-y-hidden'
            )}
            style={getStylesFilter()}
          >
            <FilterOfferSpecialStore
              list={category}
              active={activeCategory}
              isLoading={isLoading}
              onClick={(id: number) => {
                setActiveCategory(id)
              }}
            />
          </div>
        }
        {
          isShowOnlyList ? '' :
          <div
            id="offers-special-store-sorting"
            className={classNames('fixed w-full hidden')}
            style={getStylesSorting()}
          >
            <SortingOfferSpecialStore />
          </div>
        }
        <div
          id="offers-special-store-list"
          className={classNames('flex flex-col fixed w-full overscroll-y-none overflow-y-auto', {
            'hide-scroll': specialStore?.length === 0,
            hidden: isError,
          })}
          style={getStylesList()}
          onScroll={onScroll}
        >
          <SpecialStoreList
            list={specialStore}
            loading={isLoading}
            point={profile?.points || 0}
            onClick={(id: number | undefined, isFavorited: boolean | undefined, index: number, event: any) => {
              if (id) {
                handleOnSelectOfferDetail(id, isFavorited, index, false, event)
                setIsError(false)
                setRedeemErrorCode(undefined)
              }
            }}
          />
        </div>
        <Transition
          nodeRef={nodeRef}
          in={isShowingSelectedOfferDetails || isShowingSearchDetailSection}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          {(state: string) => (
            <div
              id="offers-special-store-detail"
              ref={nodeRef}
              style={{transitionDuration: `${durationTransition}ms`}}
              className={classNames(
                'bg-white fixed overscroll-y-contain top-0 bottom-0 left-0 right-0 z-[65] transition-all translate-x-full overflow-y-auto pb-[121px]',
                {
                  '!translate-x-0': state === 'entering' || state === 'entered',
                  hidden: isError,
                  'overflow-y-hidden': isShowModalRedeem,
                }
              )}
            >
              <SpecialStoreDetail
                item={specialStoreItem}
                loading={isLoadingDetails}
                isShowModal={isShowModalRedeem}
                setIsShowModal={setIsShowModalRedeem}
                isFavorited={isFavorited}
                onClick={(isFav: boolean) => {
                  if (isFav) {
                    fetchAddFavorites(currentId!, 'BZB', false)
                  } else {
                    fetchDeleteFavorites(currentId!, 'BZB', false)
                  }
                }}
              />
            </div>
          )}
        </Transition>

        <Transition
          nodeRef={nodeRef}
          in={isShowingSearchSection}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          {(state: string) => (
            <div
              id="search-section"
              ref={nodeRef}
              className={classNames(
                'flex flex-col bg-white fixed overscroll-y-contain top-0 bottom-0 left-0 right-0 z-[60] duration-' +
                  durationTransition +
                  ' transition-all translate-x-full overflow-y-auto',
                {
                  '!translate-x-0': state === 'entering' || state === 'entered',
                }
              )}
            >
              <Search
                setIsShowingSearchDetailSection={setIsShowingSearchDetailSection}
                lastFavoriteToggleData={lastFavoriteToggleData}
                points={profile?.points || 0}
                onSelectOfferDetails={(id: number, isFavorited: boolean | undefined, index: number, isSearchSection: boolean, event: any) => {
                  handleOnSelectOfferDetail(id, isFavorited, index, isSearchSection, event)
                }}
                idItemSearchListFavorites={idItemSearchListFavorites}
                setIdItemSearchListFavorites={setIdItemSearchListFavorites}
                isAddFavoritesForSearchList={isAddFavoritesForSearchList}
              />
            </div>
          )}
        </Transition>
      </div>

      <div
        id="offer-details-footer"
        className={classNames('fixed inset-x-0 bottom-0 z-[1000]', {
          hidden: isErrorProfile || isShowModalRedeem || isShowModalRedeemSuccess
        })}
      >
        <Footer
          loading={isLoading || isLoadingDetails}
          displayName={profile?.name || 'N/A'}
          cardName="มายโลตัส"
          coin={profile?.points || 0}
          isShowRedeemBtn={isShowingSelectedOfferDetails}
        >
          <button
            id="offer-details-footer-button"
            type="button"
            disabled={
              isError ||
              (specialStoreItem?.PointPerUnit || 0) > (profile?.points || 0) ||
              specialStoreItem?.Qty === 0 ||
              isLoadingRedeemOffers ||
              isDisableFooter
            }
            className={classNames(
              'text-white w-full leading-7 text-lg font-lotuss font-bold p-2 my-3 rounded-lg bg-blue-iris disabled:opacity-50',
              { hidden: !isShowingSelectedOfferDetails }
            )}
            onClick={() => {
              setIsShowModalRedeem(!isShowModalRedeem)
              setPostMessageCounter(0)
              if(!navigator.onLine) {
                setRedeemError(ERROR_GENERAL.NO_INTERNET)
                setTimeout(() => setRedeemError(undefined), 3000)
              }
            }}
          >
            รับสิทธิ์
          </button>
        </Footer>
      </div>
      <div className={classNames('fixed flex flex-row h-full bottom-[-80%] z-[100000]', { 'hidden': !successAddFavorites && !successDelFavorites && !errorAddFavorites && !errorDelFavorites })}>
        { successAddFavorites && <Alert idElement="add-favorites-alert-success"  description={successAddFavorites} icon='' /> }
        { successDelFavorites && <Alert idElement="del-favorites-alert-success"  description={successDelFavorites} icon='' /> }
        { errorAddFavorites && <Alert idElement="add-favorites-alert-error" description={errorAddFavorites} icon='information-circle' /> }
        { errorDelFavorites && <Alert idElement="del-favorites-alert-error" description={errorDelFavorites} icon='information-circle' /> }
      </div>
    </div>
  )
}

export default OffersSpecialStore
