import classNames from 'classnames'
import * as React from 'react'

interface Props {
  idElement: string
}

const CardDetailLoading: React.FC<Props> = ({ idElement }) => {
  const styleSkeleton = 'rounded-lg bg-gradient-to-l from-smoke to-white-smoke my-1'

  return (
    <div id={idElement} className="animate-pulse flex flex-col">
      <div id={`${idElement}-bg-content`} className=" flex items-center justify-center">
        <div id={`${idElement}-bg`} className=" h-[375px] w-full bg-white-smoke" />
      </div>
      <div id={`${idElement}-card-detail-content`} className="flex flex-col px-3 mt-3">
        <div
          id={`${idElement}-card-detail`}
          className="flex flex-col"
          data-testid="card-detail-detail"
        >
          <div className="flex justify-between">
            <p
              id={`${idElement}-card-detail-text0`}
              className={`${styleSkeleton} h-[16px] w-[56px]`}
            />
            <p
              id={`${idElement}-favorited-icon-loading`}
              className={classNames(
                'bg-gradient-to-l from-smoke to-white-smoke rounded w-[25px] h-[25px]',
                { hidden: !idElement.includes('offer') }
              )}
            />
          </div>

          <p
            id={`${idElement}-card-detail-text1`}
            className={`${styleSkeleton} h-[16px] w-10/12`}
          />
          <p id={`${idElement}-card-detail-text2`} className={`${styleSkeleton} h-[16px] w-7/12`} />
        </div>

        <div
          id={`${idElement}-card-detail-full`}
          data-testid="card-detail-detail"
          className="flex flex-col"
        >
          <p
            id={`${idElement}-card-detail-full-text`}
            className={`${styleSkeleton}  h-[60px] w-full`}
          />
        </div>
      </div>
    </div>
  )
}

export default CardDetailLoading
