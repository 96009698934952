import { OfferDetails, Profile } from '../../services/my-coupon-bff/types'
import { getAvailableInventoryOffers, getProfile, addFavorites, deleteFavorites } from '../../services/my-coupon-bff'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorResponse, HttpErrorResponse } from '../../utils/reponseType'
import { getParam } from '../../utils/params'
import { KEY_STORAGE, OFFERS_SPECIAL_LOTUS_TYPE, OFFERS_TYPE } from '../../utils/constant'
import { useParams } from 'react-router-dom'
import { Alert, FilterOfferLotusType, FilterOfferType, Footer, GeneralError, SortingOfferLotusStore } from '../../common'
import classNames from 'classnames'
import { OffersSpecialLotusList, OffersSpecialStore, OfferDetail } from '../../components'
import { checkNetworkError } from '../../utils/error'
import './OffersPage.css'
import { getLocalStorageString, removeLocalStorage, setLocalStorage } from '../../utils/storage'
import { Error as ErrorPremiumList } from '../../common'
import NetworkErrorLogo from '../../assets/images/network-error.svg'
import BadRequestImage from '../../assets/images/bad-request.svg'
import { addGAEvent } from '../../utils/addGoogleAnalytic'
import { getBZBToken, requestBZBToken } from '../../components/functions'
import { ModalSorting, sortingList } from './Modal'
import {  getStatusOffer, PREMIUM_STATUS } from '../../utils/status'
import dayjs from 'dayjs'
import { DATE_FORMAT, getDate } from '../../utils/date'
import Wishlist from '../../components/wishlist/Wishlist'

export interface ICounterOffer {
  premium: number
  voucher: number
  specialForYou: number
  all: number
}

let selectedOfferFavoriteStatus: boolean | undefined = undefined // for handle favorite icon error in detail

const wnd: any = window

const Offers: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [profile, setProfile] = useState<Profile>()
  const [isInternalError, setIsInternalError] = useState<boolean>(false)
  const [selectedOfferType, setSelectedOfferType] = useState<string>(OFFERS_SPECIAL_LOTUS_TYPE.ALL)
  const [offers, setOffers] = useState<OfferDetails[]>()
  const [originalOffers, setOriginalOffers] = useState<OfferDetails[]>()
  const [error, setError] = useState<ErrorResponse | null>()
  const [counterOfferByType, setCounterOfferByType] = useState<ICounterOffer>({
    premium: 0,
    voucher: 0,
    specialForYou: 0,
    all: 0,
  })
  const [selectedOfferTab, setSelectedOfferTab] = useState<number>(0)
  const [isRefreshOffers, setIsRefreshOffers] = useState<boolean>(false)
  const [isRefreshOffersSpecialStore, setIsRefreshOffersSpecialStore] = useState<boolean>(false)
  const [isDisabledAnimation, setIsDisabledAnimation] = useState<boolean>(false)

  const { offerId } = useParams()
  const dataFetchedRef = useRef(false)
  const isNetworkError = checkNetworkError()
  const [isHideFilter, setHideFilter] = useState<boolean>(false)

  const [selectedOffer, setSelectedOffer] = useState<OfferDetails | null>(null)
  const [isShowingOfferDetail, setIsShowingOfferDetail] = useState<boolean>(false)

  const [isShowSpecialStoreDetails, setIsShowSpecialStoreDetails] = useState<boolean>(false)

  // Sorting
  const [selectedSortingIndex, setSelectedSortingIndex] = useState<number>(0)
  const [isShowModalSorting, setIsShowModalSorting] = useState<boolean>(false)
  const [selectedSorting, setSelectedSorting] = useState(sortingList[0])
  const [isFree, setIsFree] = useState<boolean>(false)

  // event message to line
  const [postMessageCounter, setPostMessageCounter] = useState<number>(0)
  const TOKEN_RECEIVED_MESSAGE = 'bzb-token-received'

  // favorites
  const [successAddFavorites, setSuccessAddFavorites] = useState<string>('')
  const [successDelFavorites, setSuccessDelFavorites] = useState<string>('')
  const [errorAddFavorites, setErrorAddFavorites] = useState<string>('')
  const [errorDelFavorites, setErrorDelFavorites] = useState<string>('')

  // element menu, filter, sorting, footer of offers special lotus
  const [offersMenuElement, setOffersMenuElement] = useState<HTMLElement>()
  const [offersFilterElement, setOffersFilterElement] = useState<HTMLElement>()
  const [offersSortingElement, setOffersSortingElement] = useState<HTMLElement>()
  const [offersListElement, setOffersListElement] = useState<HTMLElement>()
  const [offersFooterElement, setOffersFooterElement] = useState<HTMLElement>()

  const [isOpenedWishlist, setIsOpenedWishlist] = useState<boolean>(getParam('type') === 'wishlist')

  const onScroll = () => {
    let menuTop = 0 - offersListElement!.scrollTop
    let filterTop = offersMenuElement!.clientHeight - offersListElement!.scrollTop
    let sortingTop = offersMenuElement!.clientHeight + offersFilterElement!.clientHeight - offersListElement!.scrollTop
    let sumMarginTop = offersMenuElement!.clientHeight + offersFilterElement!.clientHeight + offersSortingElement!.clientHeight - offersListElement!.scrollTop

    const maximumSumMarginTop = offersMenuElement!.clientHeight + offersFilterElement!.clientHeight + offersSortingElement!.clientHeight
    if (sumMarginTop <= 0) sumMarginTop = 0
    else if (sumMarginTop >= maximumSumMarginTop) sumMarginTop = maximumSumMarginTop

    offersMenuElement!.style.top = `${menuTop}px`
    offersFilterElement!.style.top = `${filterTop}px`
    offersSortingElement!.style.top = `${sortingTop}px`
    offersListElement!.style.height = `calc(100% - ${sumMarginTop + offersFooterElement!.clientHeight}px)`
    offersListElement!.style.marginTop = `${sumMarginTop}px`
  }

  const getStylesOffersFilter = () => {
    const defaultTop = 40
    let sum = 0
    if (offersMenuElement?.clientHeight) sum = offersMenuElement.clientHeight
    return { top: `${sum > 0 ? sum : defaultTop}px` }
  }

  const getStylesOffersSorting = () => {
    const defaultTop = 134
    let sum = 0
    if (!loading && offersMenuElement?.clientHeight && offersFilterElement?.clientHeight) {
      sum = offersMenuElement.clientHeight + offersFilterElement?.clientHeight
    }
    return { top: `${sum > 0 ? sum : defaultTop}px` }
  }

  const getStylesOffersList = (countList?: number) => {
    const defaultTop = 186
    const sumHeightMenu = 40
    let sum = 0
    let sumFooter = 52
    if (
      !loading && 
      offersMenuElement?.clientHeight &&
      offersFilterElement?.clientHeight &&
      offersSortingElement?.clientHeight &&
      offersFooterElement?.clientHeight
    ) {
      sum = offersMenuElement.clientHeight + offersFilterElement.clientHeight + offersSortingElement.clientHeight
      sumFooter = offersFooterElement.clientHeight
    } else if (!loading && (!countList || countList === 0)) {
      sum = sumHeightMenu
    }
    return {
      height: `calc(100% - ${(sum > 0 ? sum : defaultTop) + sumFooter}px)`,
      marginTop: `${sum > 0 ? sum : defaultTop}px`,
    }
  }

  const assignElementToVariables = () => {
    setOffersMenuElement(document.getElementById('offers-menu')!)
    setOffersFilterElement(document.getElementById('offers-filter')!)
    setOffersSortingElement(document.getElementById('offers-sorting')!)
    setOffersListElement(document.getElementById('offers-list-container')!)
    setOffersFooterElement(document.getElementById('offers-footer')!)
  }

  const resetSort = useCallback(() => {
    setSelectedSorting(sortingList[0])
    setSelectedSortingIndex(0)
    setIsFree(false)
  }, [])

  async function refreshProfileAndOffers() {
    setError(null)
    setIsInternalError(false)
    setLoading(true)

    if (selectedOfferTab === 0) {
      fetchProfile()
      setIsRefreshOffers(true)
    } else {
      setIsRefreshOffersSpecialStore(true)
    }
  }

  const handleSelectedMenu = useCallback(
    (index: number) => {
      setSelectedOfferTab(index)
      setError(null)
      removeLocalStorage('special-store-id')
      if (index === 0) {
        setLoading(true)
        setIsRefreshOffers(true)
        setSelectedOfferType(OFFERS_SPECIAL_LOTUS_TYPE.ALL)
        localStorage?.setItem('tab', 'offer-list')

        window.parent.postMessage('selected-offer-list', '*')
        removeLocalStorage('search')
      } else {
        const bzbToken = getBZBToken()
        setIsRefreshOffersSpecialStore(true)
        localStorage?.setItem('tab', 'offer-special-list')
        if(!bzbToken) requestBZBToken(postMessageCounter, setPostMessageCounter)
        window.parent.postMessage('selected-offer-special-list', '*')
        resetSort()
      }
    },
    [
      setSelectedOfferTab, 
      setLoading, 
      setIsRefreshOffers, 
      setError, 
      setSelectedOfferType,
      postMessageCounter,
      resetSort,
    ]
  )

  const filterOffersByType = (type: string, offersData: OfferDetails[]) => {
    if (type && offersData) {
      return offersData.filter((offer) => offer.offerDetails.offerType === type)
    }
    return offersData
  }

  const onSelectOffer = (selectedOffer: OfferDetails) => {
    document.getElementById('scrollable-offer-detail')?.scrollTo({ top: 0 })
    setSelectedOffer(selectedOffer)
    setIsShowingOfferDetail(true)
    selectedOfferFavoriteStatus = selectedOffer.isFavorited
    localStorage?.setItem('tab', 'offer-detail')
  }

  const counterOfferType = useCallback(
    (offerDetails: OfferDetails[]) => {
      const premiumType = []
      const voucherType = []
      const specialForYou = []

      if (offerDetails.length > 0) {
        offerDetails.forEach((offer) => {
          if (offer) {
            if (offer.offerDetails.offerType === OFFERS_TYPE.PREMIUM) {
              premiumType.push(offer)

              return offer
            }
            if (offer.offerDetails.offerType === OFFERS_TYPE.VOUCHER) {
              voucherType.push(offer)

              return offer
            }
            if (offer.offerDetails.offerType === OFFERS_TYPE.SPECIAL_FOR_YOU) {
              specialForYou.push(offer)

              return offer
            }
          }
        })
      }

      setCounterOfferByType({
        premium: premiumType.length,
        voucher: voucherType.length,
        specialForYou: specialForYou.length,
        all: offerDetails.length,
      })
    },
    [setCounterOfferByType]
  )

  const sortOffersByDate = (offers: OfferDetails[]) => {
    return offers.sort((a, b) => {
      return dayjs(a.redemptionStartDate).isAfter(dayjs(b.redemptionStartDate))
        ? 1
        : -1
    })
  }

  const orderOffer = useCallback((list: OfferDetails[], free: boolean): OfferDetails[] => {
    let offerReadyToUse: OfferDetails[] = []
    let offerCoimingSoon: OfferDetails[] = []
    let offerUnavaliable: OfferDetails[] = []
    let offerWithSortingRule: OfferDetails[] = []
    let offerList : OfferDetails[] = []

    list.forEach((offer) => {
      const expiryDate = getDate(offer.offerDetails.acquisitionEndDate!, DATE_FORMAT)
      const status = getStatusOffer({ 
        startDate: offer.redemptionStartDate,
        endDate: expiryDate, 
        point: profile?.points,
        price: offer.price,
        available: offer.availableInventoryRemaining,
      })

      if (status === PREMIUM_STATUS.EMPTY || status === PREMIUM_STATUS.NOT_ENOUGH) {
        offerUnavaliable.push(offer) 
      } else if (status === PREMIUM_STATUS.COMING_SOON) {
        offerCoimingSoon.push(offer)
      } else if (status === PREMIUM_STATUS.VALID) {
        offerReadyToUse.push(offer)
      } else {
        offerWithSortingRule.push(offer)
      }
    })

    if (offerReadyToUse.length) {
      offerList = [...offerList, ...offerReadyToUse]
    }
    if (offerCoimingSoon.length) {
      const data = sortOffersByDate(offerCoimingSoon)
      offerList = [...offerList, ...data]
    }
    if (offerUnavaliable.length) {
      offerList = [...offerList, ...offerUnavaliable]
    }
    if (free) {
      offerList = offerList?.filter((offer) => offer.price === 0)
    }
    return [...offerList, ...offerWithSortingRule]
  }, [profile?.points])

  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getProfile()

      if (response) {
        const result = response as Profile

        setProfile(result)
      }
    } catch (e) {
      const error = e as ErrorResponse
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      if (error.code === 500) {
        setIsInternalError(true)
      }
      setLoading(false)
    }
  }, [])


  const fetchOffers = useCallback(async () => {
    setError(null)
    setLoading(true)

    try {
      const response = await getAvailableInventoryOffers(selectedSorting.param, true)

      if (response) {
        let offerDetails = response as OfferDetails[] | []

        counterOfferType(offerDetails)

        if (offerId) {
          const selectedOffer = offerDetails.find((offer) => offer.id === offerId) || null
          if (selectedOffer) {
            setSelectedOffer(selectedOffer)
            selectedOfferFavoriteStatus = selectedOffer.isFavorited
          } else {
            setIsShowingOfferDetail(false)
          }
        }

        const orderedOffers = orderOffer(offerDetails, isFree)
        setOriginalOffers(orderedOffers)
        setOffers(orderedOffers)
      }
      setLoading(false)
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      setError(e as ErrorResponse)
      setLoading(false)
    }
  }, [
    offerId, 
    selectedSorting, 
    orderOffer, 
    isFree,
    counterOfferType
  ])

  const getSubMenuList = useCallback(() => {
    const subMenu = []

    if (counterOfferByType.all) {
      subMenu.push({ title: 'ทั้งหมด', icon: 'all-icon', type: OFFERS_SPECIAL_LOTUS_TYPE.ALL })
    }
    if (counterOfferByType.voucher) {
      subMenu.push({
        title: 'คูปองเงินสดโลตัส',
        icon: 'voucher-icon',
        type: OFFERS_SPECIAL_LOTUS_TYPE.VOUCHER,
      })
    }
    if (counterOfferByType.premium) {
      subMenu.push({
        title: 'แลกของพรีเมียม',
        icon: 'premium-icon',
        type: OFFERS_SPECIAL_LOTUS_TYPE.PREMIUM,
      })
    }
    if (counterOfferByType.specialForYou) {
      subMenu.push({
        title: 'พิเศษเฉพาะคุณ',
        icon: 'special-for-you-icon',
        type: OFFERS_SPECIAL_LOTUS_TYPE.SPECIAL_FOR_YOU,
      })
    }
    return subMenu
  }, [counterOfferByType])

  const handleParentEvent = useCallback(
    async (event: MessageEvent) => {
      // Constants
      const PARENT_NAVIGATE_BACK = 'parent:navigate_back'
      const DEFAULT_TAB = 'offer-list'
      const specialStoreId = getLocalStorageString('special-store-id') || ''
      const action = getParam('action')
      const tab = getLocalStorageString('tab')
      const isNestedPage = getLocalStorageString('search') || false

      const handleNavigateBack = () => {
        if (isShowingOfferDetail && !action) {
          // Close the offer detail view
          setIsShowingOfferDetail(false)
          setTimeout(() => {
            setSelectedOffer(null)
            selectedOfferFavoriteStatus = undefined
          }, 300)
          localStorage?.setItem('tab', DEFAULT_TAB)
        } else if (tab === DEFAULT_TAB || tab === 'offer-special-list') {
          if (selectedOfferTab === 0 && tab === 'offer-special-list') {
            localStorage?.setItem('tab', DEFAULT_TAB)
            removeLocalStorage('special-store-id')
          }
          // Handle navigation for offer list or special offer list
          else if (specialStoreId) {
            removeLocalStorage('special-store-id')
            setIsRefreshOffersSpecialStore(true)
          } else if (isNestedPage && isNestedPage !== 'false') {
            window.parent.postMessage('search-offer-special', '*')
          } else {
            if (isOpenedWishlist) {
              localStorage.removeItem('back-action-btn')
              setIsOpenedWishlist(false)
            }
            window.parent.postMessage(PARENT_NAVIGATE_BACK, '*')
          }
        }
        if (action) {
          if (window.parent) window.parent.postMessage('parent:navigate_back', '*')
        }
      }
      // request BZBToken frist time
      if (postMessageCounter === 0) requestBZBToken(postMessageCounter, setPostMessageCounter)

      if (typeof event.data === 'string') {
        if (event.data.match(TOKEN_RECEIVED_MESSAGE)) {
          const token = JSON.parse(event.data)?.token
          const userId = JSON.parse(event.data)?.userId
          if (token && userId) {
            localStorage.setItem('bzb_user_token', token)
            localStorage.setItem('bzb_user_id', userId)
          } else {
            requestBZBToken(postMessageCounter, setPostMessageCounter)
          }
        }
      }
      switch (event.data) {
        case 'iframe:navigate_back':
          if (localStorage.getItem('back-action-btn') === 'offer-wishlist') {
            setIsOpenedWishlist(true)
          }
          else if (tab === 'offer-special-detail' && selectedOfferTab === 1) {
            setIsRefreshOffersSpecialStore(true)
            removeLocalStorage('special-store-id')
            if (isNestedPage && isNestedPage !== 'false') {
              window.parent.postMessage('search-offer-special', '*')
            }
            if (!isNestedPage) {
              localStorage?.setItem('tab', DEFAULT_TAB)
            }
          }
          handleNavigateBack()
          break

        case KEY_STORAGE.COUPONS_EXCHANGED_LIST:
          setLocalStorage(KEY_STORAGE.COUPONS_EXCHANGED_LIST, true)
          break

        default:
          if (tab === DEFAULT_TAB || tab === 'offer-special-list') {
            if (tab === 'offer-special-list') removeLocalStorage('special-store-id')
          }
          break
      }
    },
    [isShowingOfferDetail, selectedOfferTab, postMessageCounter, isOpenedWishlist]
  )

  const updateOffersSpecialLotusForFavorites = useCallback((isAddFav: boolean, id: string) => {
    if (originalOffers && originalOffers.length > 0) {
      const updatedOriginalOffers = originalOffers?.map(item => ({
        ...item,
        isFavorited: item.id === id ? isAddFav : item.isFavorited
      }))
      setOriginalOffers(updatedOriginalOffers)
    }
    if (offers && offers.length > 0) {
      const updatedOffers = offers?.map(item => ({
        ...item,
        isFavorited: item.id === id ? isAddFav : item.isFavorited
      }))
      setOffers(updatedOffers)
    }
  }, [originalOffers, offers])

  const hideAllMessageFavorites = () => {
    setSuccessAddFavorites('')
    setErrorAddFavorites('')
    setSuccessDelFavorites('')
    setErrorDelFavorites('')
  }

  const fetchAddFavorites = useCallback(async (id: string, couponSource: string) => {
    updateOffersSpecialLotusForFavorites(true, id)
    hideAllMessageFavorites()
    setSuccessAddFavorites('บันทึกไปยังรายการที่ถูกใจแล้ว<span class="absolute right-0">ดู</span>')

    const offer = offers?.find((offer => offer.id === id))

    if (offer) {
      offer.isFavorited = true
      setSelectedOffer(offer)
    }

    try {
      await addFavorites(id, couponSource)
      selectedOfferFavoriteStatus = true
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    } catch (e) {
      const tab = getLocalStorageString('tab')
      if (tab === 'offer-list') {
        updateOffersSpecialLotusForFavorites(false, id)
      } else {
        updateOffersSpecialLotusForFavorites(selectedOfferFavoriteStatus ?? false, id)
      }

      if (offer) {
        offer.isFavorited = selectedOfferFavoriteStatus
        setSelectedOffer(offer)
      }

      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      const error = e as ErrorResponse
      setErrorAddFavorites(`ขออภัย เกิดข้อผิดพลาดในระบบ (${error.code})<br/>เรากำลังแก้ปัญหา และจะกลับมาใช้งานได้เร็วๆนี้`)
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    }
  }, [updateOffersSpecialLotusForFavorites])

  const fetchDeleteFavorites = useCallback(async (id: string, couponSource: string) => {
    updateOffersSpecialLotusForFavorites(false, id)
    hideAllMessageFavorites()
    setSuccessDelFavorites('ลบออกจากรายการที่ถูกใจแล้ว')
    const offer = offers?.find((offer => offer.id === id))

    if (offer) {
      offer.isFavorited = false
      setSelectedOffer(offer)
    }

    try {
      await deleteFavorites(id, couponSource)
      selectedOfferFavoriteStatus = false
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    } catch (e) {
      //selectedOfferFavoriteStatus has value when in detail page
      const tab = getLocalStorageString('tab')
      if (tab === 'offer-list') {
        updateOffersSpecialLotusForFavorites(true, id)
      } else {
        updateOffersSpecialLotusForFavorites(selectedOfferFavoriteStatus ?? true, id)
      }
      
      if (offer) {
        offer.isFavorited = selectedOfferFavoriteStatus

        setSelectedOffer(offer)
      }
      
      if (e instanceof HttpErrorResponse) {
        if (e.code === 401) {
          return
        }
      }
      const error = e as ErrorResponse
      setErrorDelFavorites(`ขออภัย เกิดข้อผิดพลาดในระบบ (${error.code})<br/>เรากำลังแก้ปัญหา และจะกลับมาใช้งานได้เร็วๆนี้`)
      setTimeout(() => { hideAllMessageFavorites() }, 5000)
    }
  }, [updateOffersSpecialLotusForFavorites])

  useEffect(() => {
    if (window) {
      const requestIdString = getParam('x-request-id')

      if (requestIdString) {
        const requestIdList = requestIdString.split(',')

        window.__IFRAME_DEBUGGER_PARAMS = requestIdList ?? []
      }
    }

    const type = getParam('type')

    // for offer special lotus detail
    if (offerId && type !== 'special-store') {
      setIsDisabledAnimation(true)
      setTimeout(() => {
        setIsDisabledAnimation(false)
      }, 50)
      setIsShowingOfferDetail(true)
    }
    
    // for offer special store
    if (type === 'special-store') {
      if (offerId) {
        setIsShowSpecialStoreDetails(true)
        setSelectedOfferTab(1)
        setIsRefreshOffersSpecialStore(true)
        setLocalStorage('special-store-id-from-param', true)
      } else {
        handleSelectedMenu(1)
      }
    }
  }, [handleSelectedMenu, offerId])

  useEffect(() => {
    assignElementToVariables()
  }, [])

  useEffect(() => {
    if (dataFetchedRef.current) return

    if (!profile && !offers) {
      localStorage?.setItem('tab', 'offer-list')
      removeLocalStorage('special-store-id')
      removeLocalStorage('search')
      fetchProfile()
      fetchOffers()
    }

    dataFetchedRef.current = true
  }, [fetchProfile, offers, profile, fetchOffers])

  useEffect(() => {
    if (isRefreshOffers) {
      setIsRefreshOffers(false)
      fetchOffers()
    }
  }, [isRefreshOffers, fetchOffers])

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleParentEvent)
    }
    return () => {
      window.removeEventListener('message', handleParentEvent)
    }
  }, [isShowingOfferDetail, handleParentEvent])

  return (
    <div
      data-testid="offers-page"
      id="offers-page"
      className={classNames('offers', 'h-[100svh] overflow-y-hidden')}
    >
      <ModalSorting 
        idElement='modal-sorting'
        isShowModal={isShowModalSorting}
        setIsShowModalSorting={setIsShowModalSorting}
        selectedSortingIndex={selectedSortingIndex}
        setSelectedSortingIndex={setSelectedSortingIndex}
        setSelectedSorting={setSelectedSorting}
        onSelectedSorting={() => {
          setIsRefreshOffers(!isRefreshOffers)
        }}
      />
      <div id="offers-network-error" className={classNames({ hidden: !isNetworkError })}>
        <GeneralError
          idElement="offers-network-general-error"
          title={'เครือข่ายขัดข้อง'}
          detail={'กรุณาตรวจสอบการเชื่อมต่อและลองใหม่อีกครั้ง'}
          image={NetworkErrorLogo}
          onClick={() => refreshProfileAndOffers()}
        />
      </div>
      <div id="offers-internal-error" className={classNames({ hidden: !isInternalError })}>
        <GeneralError
          idElement="offers-internal-general-error"
          title={'ขออภัย มีบางอย่างผิดพลาด'}
          detail={'กรุณาลองอีกครั้ง'}
          image={BadRequestImage}
          onClick={() => refreshProfileAndOffers()}
        />
      </div>
      <div
        data-testid="offers-menu"
        id="offers-menu"
        className={classNames('fixed top-0 w-full h-[40px]', {
          hidden: isNetworkError || isInternalError || isHideFilter,
        })}
      >
        <FilterOfferType
          onSelectMenu={(index: number) => handleSelectedMenu(index)}
          active={selectedOfferTab}
        />
      </div>
      <div
        data-testid="offers-filter"
        id="offers-filter"
        className={classNames('fixed w-full', {
          hidden:
            error ||
            isNetworkError ||
            isInternalError ||
            selectedOfferTab !== 0 ||
            (!loading && getSubMenuList().length === 0)
        })}
        style={getStylesOffersFilter()}
      >
        <FilterOfferLotusType
          list={getSubMenuList()}
          active={selectedOfferType}
          onClick={(type: string) => {
            setSelectedOfferType(type)
            setIsRefreshOffers(true)
            const menuType =
              type === OFFERS_SPECIAL_LOTUS_TYPE.PREMIUM
                ? 'แลกของพรีเมียม'
                : type === OFFERS_SPECIAL_LOTUS_TYPE.VOUCHER
                ? 'คูปองส่วนลดเงินสด'
                : type === OFFERS_SPECIAL_LOTUS_TYPE.SPECIAL_FOR_YOU
                ? 'พิเศษเฉพาะคุณ'
                : 'ทั้งหมด'
            addGAEvent({
              event: 'offer_category_icon',
              menu: menuType,
            })
          }}
          loading={loading}
        />
      </div>
      <div id="offers-sorting" 
        className={classNames('fixed w-full', 
        { hidden: 
          error ||
          isNetworkError ||
          isInternalError ||
          selectedOfferTab !== 0 ||
          (!loading && getSubMenuList().length === 0),
        })}
        style={getStylesOffersSorting()}
      >
        <SortingOfferLotusStore 
          loading={loading}
          isFree={isFree}
          isShowModalSorting={isShowModalSorting} 
          setIsShowModalSorting={setIsShowModalSorting} 
          title={selectedSorting.title}
          setIsFree={(free: boolean) => {
            const offer = orderOffer(offers!, free)

            setOffers(free ? offer : originalOffers)
            setIsFree(free)
          }}
        />
      </div>
      <div
        data-testid="offers-list"
        id="offers-list-container"
        className={classNames('flex flex-col fixed w-full overscroll-y-none overflow-y-auto', {
          hidden: selectedOfferTab !== 0 || error,
          'hide-scroll': offers?.length === 0 || loading,
        })}
        style={getStylesOffersList(offers?.length)}
        onScroll={onScroll}
      >
        <OffersSpecialLotusList
          offers={filterOffersByType(selectedOfferType, offers!) || null}
          loading={loading}
          setLoading={setLoading}
          error={error || null}
          name={profile?.name || 'N/A'}
          point={profile?.points}
          isHideSubMenu={selectedOfferTab !== 0}
          onRefresh={() => setIsRefreshOffers(true)}
          onSelectOffer={onSelectOffer}
          fetchAddFavorites={fetchAddFavorites}
          fetchDeleteFavorites={fetchDeleteFavorites}
        />
      </div>
      <div
        id="offers-store-list-container"
        data-testid="offers-special-list"
        className={classNames({ hidden: selectedOfferTab !== 1 || isNetworkError })}
      >
        <OffersSpecialStore
          isRefresh={isRefreshOffersSpecialStore}
          onRefresh={setIsRefreshOffersSpecialStore}
          setHideFilter={setHideFilter}
          isHideFilter={isHideFilter}
          isShowSpecialStoreDetails={isShowSpecialStoreDetails}
        />
      </div>
      <div
        id="offers-error-premium-list-container"
        className={classNames('error-page-container', {
          hidden: !error || selectedOfferTab === 1,
          'flex justify-center h-[calc(100%-92px)] mt-[40px]': error && selectedOfferTab === 0,
        })}
      >
        <ErrorPremiumList
          idElement="offers-error-premium-list"
          onClick={() => {
            setIsRefreshOffers(true)
          }}
          status={error?.code || 500}
          refCode={error?.refCode || ''}
        />
      </div>
      <div
        id="offer-details-container"
        className={classNames('offer-details', {
          show: isShowingOfferDetail,
          hide: !isShowingOfferDetail,
          'disabled-animation': isDisabledAnimation,
        })}
      >
        <OfferDetail
          loading={loading}
          name='Lotus’s'
          offer={selectedOffer}
          setLoading={setLoading}
          profile={profile}
          fetchAddFavorites={fetchAddFavorites}
          fetchDeleteFavorites={fetchDeleteFavorites}
        />
      </div>
      <div
        id="offers-footer"
        data-testid="offers-footer"
        className={classNames('fixed inset-x-0 bottom-0 z-[5]', {
          hidden: isNetworkError || isInternalError || selectedOfferTab === 1,
        })}
        onClick={() =>
          addGAEvent({
            event: 'mylotuss_card',
          })
        }
      >
        <Footer
          loading={loading}
          displayName={profile?.name || 'N/A'}
          cardName="มายโลตัส"
          coin={profile?.points || 0}
        />
      </div>
      <div className={classNames('fixed flex flex-row h-full bottom-[-80%] z-[100000]', { 'hidden': !successAddFavorites && !successDelFavorites && !errorAddFavorites && !errorDelFavorites })}>
        { successAddFavorites && <Alert idElement="add-favorites-alert-success"  description={successAddFavorites} icon='' /> }
        { successDelFavorites && <Alert idElement="del-favorites-alert-success"  description={successDelFavorites} icon='' /> }
        { errorAddFavorites && <Alert idElement="add-favorites-alert-error" description={errorAddFavorites} icon='information-circle' /> }
        { errorDelFavorites && <Alert idElement="del-favorites-alert-error" description={errorDelFavorites} icon='information-circle' /> }
      </div>
      <div className={classNames('wishlist-container', { 'show': isOpenedWishlist })}>
        <Wishlist 
          onSelectOffer={onSelectOffer} 
          fetchAddFavorites={fetchAddFavorites} 
          fetchDeleteFavorites={fetchDeleteFavorites} 
          isLoadingProfile={loading}
          profile={profile}
          isOpenDetail={isOpenedWishlist}
        />
      </div>
    </div>
  )
}

export default Offers
