import classNames from 'classnames'
import * as React from 'react'

interface IList {
  icon: string
  title: string
  type: string
}

interface Props {
  list: IList[]
  onClick: Function
  active: string
  loading: boolean
}

const FilterOfferLotusTypeLoading = () => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div id="filter-offer-lotus-type-loading" className='animate-pulse flex flex-row mt-[12px] h-[82px]'>
      <div id="filter-offer-lotus-type-loading-content0" className='flex flex-col items-center w-[84px]'>
        <p id="filter-offer-lotus-type-loading-content0-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-lotus-type-loading-content0-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
      <div id="filter-offer-lotus-type-loading-content1" className='flex flex-col items-center w-[84px]'>
        <p id="filter-offer-lotus-type-loading-content1-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-lotus-type-loading-content1-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
      <div id="filter-offer-lotus-type-loading-content2" className='flex flex-col items-center w-[84px]'>
        <p id="filter-offer-lotus-type-loading-content2-text0" className={`${styleSkeleton} rounded-full w-[48px] h-[48px] mb-2`} />
        <p id="filter-offer-lotus-type-loading-content2-text1" className={`${styleSkeleton} rounded-lg w-[64px] h-[12px] mb-2`} />
      </div>
    </div>
  )
}

const FilterOfferLotusType: React.FC<Props> = ({ list, onClick, active, loading }) => {
  if (loading) {
    return <FilterOfferLotusTypeLoading />
  }

  return (
    <div id="filter-offer-lotus-type" className={classNames('flex flex-row mt-[12px]')}>
      {list.map(({ icon, title, type }, index) => {
        const image = active !== type ? `${icon}-disable` : icon

        return <div
          id={`filter-offer-lotus-type-content${index}`}
          className='flex flex-col items-center w-[84px]'
          key={type}
          onClick={() => {
            onClick(type)
            localStorage.setItem('refresh-offers-list', 'true')
          }}
        >
          <img
            id={`filter-offer-lotus-type-image${index}`}
            src={`/icons/${image}.svg`}
            alt='icon'
            className={classNames(
              'rounded-lg w-[46px]',
              { 'fill-slate-700': true }
            )} />
          <div
            id={`filter-offer-lotus-type-title${index}`}
            key={index}
            className={classNames(
              'text-center px-[8px] rounded-xl text-xs leading-[18px] tracking-wide',
              {
                'text-blue-iris': active === type,
                'text-gray-secondary': active !== type,
              }
            )}
          >
            {title}
          </div>
        </div>
      })}
    </div>
  )
}

export default FilterOfferLotusType