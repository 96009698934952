import React, { Fragment, useEffect, useState } from 'react'
import { DATE_FORMAT, getDate } from '../../utils/date'
import { DELIVERY_TYPE, LOTUS } from '../../utils/constant'
import { mappingImageDefault } from '../../utils/mapper'
import { DeliveryGroup } from '../../components/coupons/couponsLotusDetails/CouponsLotusDetails.model'
import classNames from 'classnames'
import './CardDetail.css'

const parse = require('html-react-parser')

interface Props {
  idElement: string
  image: string
  name: string
  startDate: string
  expiryDate: string
  description: string
  condition: string
  children: string | JSX.Element | JSX.Element[]
  showFooterRedeem?: boolean
  displayName?: string
  point?: number
  offerPrice?: number | null
  channel?: string[]
  deliveryGroup?: DeliveryGroup
  redemptionStartDate?: string
  isFavorited?: boolean
  onClick?: Function
}

const CardDetail: React.FC<Props> = (props) => {
  const {
    idElement,
    image,
    name,
    startDate,
    expiryDate,
    description,
    condition,
    children,
    channel,
    deliveryGroup,
    displayName,
    redemptionStartDate,
    isFavorited,
    onClick,
  } = props
  const expiryDateWithFormat = getDate(expiryDate, DATE_FORMAT)
  const startDateWithFormat = getDate(startDate, DATE_FORMAT)
  const matchImage = image ? mappingImageDefault(image) : undefined
  const [favorited, setFavorited] = useState<boolean>(false)

  useEffect(() => {
    if (isFavorited !== undefined) {
      setFavorited(isFavorited)
    }
  }, [isFavorited])

  return (
    <Fragment>
      <div id={idElement} className="flex flex-col items-center justify-center">
        {image && (
          <div>
            <img
              id={`${idElement}-image`} 
              data-testid="card-detail-image"
              src={matchImage}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/icons/coupon-image-placeholder.svg'
                currentTarget.className = 'flex'
                currentTarget.parentElement!.className =
                  'flex items-center justify-center flex-1 w-full aspect-square bg-[#F3F3F3]'
              }}
              className="flex-1 min-h-[100vw] object-contain bg-[#F3F3F3] h-[375px]"
              alt="card-detail"
            />
          </div>
        )}

        {!image && (
          <div id={`${idElement}-default`} className="flex items-center justify-center flex-1 w-full aspect-square bg-[#F3F3F3]">
            <img
              id={`${idElement}-default-image`}
              src="/icons/coupon-image-placeholder.svg"
              alt="img-placeholder"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/icons/coupon-image-placeholder.svg'
              }}
            />
          </div>
        )}

        {/* {channel && (
          <div className="flex text-center w-full">
            {channel.map((item) => {
              return (
                <Fragment key={item}>
                  {item.toLocaleLowerCase() === CHANNEL_TYPE.ONLINE && (
                    <p className="w-full text-2xl leading-[3.75rem] text-gray-primary font-medium font-lotuss bg-mint-green text-white">
                      {CHANNEL_TYPE.ONLINE_TH}
                    </p>
                  )}
                  {item.toLocaleLowerCase() === CHANNEL_TYPE.OFFLINE && (
                    <p className="w-full text-2xl leading-[3.75rem] text-gray-primary font-medium font-lotuss bg-[#FFE500]">
                      {CHANNEL_TYPE.OFFLINE_TH}
                    </p>
                  )}
                </Fragment>
              )
            })}
          </div>
        )} */}
      </div>

      <div id={`${idElement}-delivery-group-content`} className="flex flex-col px-[12px]">
        <div className="flex flex-row">
          <div
            id={`${idElement}-delivery-group`}
            className="flex flex-col my-[24px] w-screen"
            data-testid="card-detail-detail"
          >
            {deliveryGroup?.isOnline && (
              <div
                id={`${idElement}-delivery-type`}
                className="flex text-center w-[calc(100vw-20px)]"
              >
                {deliveryGroup.delivery.immediately.length > 0 && (
                  <p
                    id={`${idElement}-delivery-immediately`}
                    className="mr-1 mb-4 px-1.5 py-[0.1875rem] rounded-md text-sm text-gray-primary font-medium font-lotuss bg-[#F8FBD8]"
                  >
                    {DELIVERY_TYPE.IMMEDIATELY_TH}
                  </p>
                )}
                {deliveryGroup.delivery.nextDay.length > 0 && (
                  <p
                    id={`${idElement}-delivery-next-day`}
                    className="mr-1 mb-4 px-1.5 py-[0.1875rem] rounded-md text-sm text-gray-primary font-medium font-lotuss bg-[#E1FFFE]"
                  >
                    {DELIVERY_TYPE.NEXT_DAY_TH}
                  </p>
                )}
                {deliveryGroup.delivery.inStore.length > 0 && (
                  <p
                    id={`${idElement}-delivery-in-store`}
                    className="mr-1 mb-4 px-1.5 py-[0.1875rem] rounded-md text-sm text-gray-primary font-medium font-lotuss bg-[#E9F9F8]"
                  >
                    {DELIVERY_TYPE.IN_STORE_TH}
                  </p>
                )}
              </div>
            )}
            {!deliveryGroup && (
              <p
                id={`${idElement}-no-delivery`}
                className="leading-5 text-sm font-lotuss text-gray-secondary"
              >
                {displayName || LOTUS}
              </p>
            )}
            <h4
              id={`${idElement}-text-name`}
              className="leading-7 text-lg font-bold font-lotuss py-1 text-gray-primary"
            >
              {name}
            </h4>
            {redemptionStartDate ? (
              <p className="text-danger">{`เริ่มใช้ ${getDate(
                redemptionStartDate,
                'DD MMM BBBB, HH:mm'
              )}`}</p>
            ) : (
              <p
                id={`${idElement}-expiry-date`}
                className="leading-6 text-base font-lotuss text-gray-secondary"
              >
                {startDateWithFormat} - {expiryDateWithFormat}
              </p>
            )}
          </div>
          {isFavorited !== undefined ? (
            <div id={`${idElement}-favorited`}>
              <img
                id={`${idElement}-favorited-icon`}
                data-testid="favorited-icon"
                src={favorited ? '/icons/like-icon.svg' : '/icons/unlike-icon.svg'}
                className={classNames('w-[30px] max-h-23 my-[24px]', 
                  { 'favorite-animate--active': favorited, 'favorite-animate--inactive': !favorited })}
                alt="favorited"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.className = 'hidden'
                }}
                onClick={() => {
                  setFavorited(!favorited)
                  if (onClick) onClick(!favorited)
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        {children}

        <div
          id={`${idElement}-description-content`}
          data-testid="card-detail-detail"
          className="flex flex-col my-[24px]"
        >
          <h4
            id={`${idElement}-description-header`}
            className="text-lg leading-7 font-bold font-lotuss text-gray-primary mb-[12px]"
          >
            รายละเอียด
          </h4>
          <div
            id={`${idElement}-description`}
            className="text-base text-gray-empty leading-6 [&>*]:font-lotuss [&>*]:font-medium"
          >
            {description ? parse(description) : ''}
          </div>
        </div>

        <hr id={`${idElement}-separate-line`} className="my-0 border-0.5 border-[#E0E0E0]" />

        <div
          id={`${idElement}-condition-content`}
          data-testid="card-detail-detail"
          className="flex flex-col my-[24px]"
        >
          <h4
            id={`${idElement}-condition-header`}
            className="text-lg leading-7 font-bold font-lotuss text-gray-primary mb-2"
          >
            เงื่อนไข
          </h4>
          <div
            id={`${idElement}-condition`}
            className="text-base text-gray-empty leading-6 [&>*]:font-lotuss [&>*]:font-medium"
          >
            {condition ? parse(condition) : ''}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CardDetail
