import {
  getOffers,
  getPartnerOffers,
  getAvailableInventoryOffers,
} from "../../services/my-coupon-bff"
import { getParam } from "../../utils/params"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { getBZBToken } from "../functions"
import { DATE_FORMAT, getDate } from "../../utils/date"
import { getStatus } from "../../utils/status"
import classNames from "classnames"
import { LOTUS } from "../../utils/constant"
import { Card, Footer } from "../../common"
import { addGAEvent } from "../../utils/addGoogleAnalytic"
import { OfferDetails, PartnerOffers, Profile } from "../../services/my-coupon-bff/types"
import SpecialStoreList from "../offers/specialStore/specialStoreList/SpecialStoreList"
import { sortingList } from "../../pages/offers/Modal"
import EmptyList from "./EmptySpecialStoreList"
import Loading from "./Loading"

export interface Props {
  onSelectOffer: (offerDetails: OfferDetails) => void
  fetchAddFavorites: (id: string, couponSource: string) => void
  fetchDeleteFavorites: (id: string, couponSource: string) => void
  isLoadingProfile: boolean
  profile?: Profile
  isOpenDetail: boolean
}

const Wishlist: React.FC<Props> = ({
  profile,
  isOpenDetail,
  onSelectOffer,
  isLoadingProfile,
  fetchAddFavorites,
  fetchDeleteFavorites,
}) => {
  const [storeRewardOffers, setStoreRewardOffers] = useState<OfferDetails[]>()
  const [bzbOffers, setBZBOffers] = useState<PartnerOffers[]>()
  const [loading, setLoading] = useState<boolean>()

  const token: string = getParam("token") || localStorage?.getItem("token") || ""
  const bzbToken = getBZBToken()

  const loadStoreRewardOffers = useCallback(async () => {
    try {
      const offers = await getAvailableInventoryOffers(
        sortingList[0].param,
        true
      )

      if (offers) {
        const favoritedOffers = (offers as OfferDetails[]).filter(
          (offer) => offer.isFavorited
        )
        setStoreRewardOffers(favoritedOffers)
      }
    } catch {
      setStoreRewardOffers([])
    }
  }, [])

  const loadBzbOffers = useCallback(async () => {
    try {
      const bzbOffers = (await getPartnerOffers(
        token,
        bzbToken,
        true
      )) as PartnerOffers[]
      const favoritedBZBOffers = bzbOffers.filter((offer) => offer.isFavorited)
      setBZBOffers(favoritedBZBOffers)
    } catch {
      setBZBOffers([])
    }
  }, [bzbToken, token])

  const handleFavoritedAction = (type: 'bzb' | 'lotus', id: string, index: number) => {
    const isFavorited = type === 'bzb'
      ? bzbOffers?.[index]?.isFavorited
      : storeRewardOffers?.[index]?.isFavorited
  
    if (isFavorited) {
      fetchDeleteFavorites(id, "MAGENTO")
    } else {
      fetchAddFavorites(id, "MAGENTO")
    }
  
    // Update the offers list
    const updateOffers = (offers: any[] | undefined) => 
      offers?.map((offer) =>
        offer?.ID?.toString() === id || offer?.id === id
          ? { ...offer, isFavorited: !offer.isFavorited }
          : offer
      )
  
    if (type === 'bzb') {
      setBZBOffers(updateOffers(bzbOffers))
    } else if (type === 'lotus') {
      setStoreRewardOffers(updateOffers(storeRewardOffers))
    }
  }

  const handleSelectOfferDetail = (type: 'bzb' | 'lotus', index: number, id: string) => {
    let internalName = ''
  
    localStorage.setItem("back-action-btn", "offer-wishlist")
  
    if (type === 'bzb') {
      window.location.href = `/offers/${id}?type=special-store`
      internalName = bzbOffers?.[index]?.Name || ''
    } else {
      internalName = storeRewardOffers?.[index]?.offerDetails?.internalName || ''
      onSelectOffer(storeRewardOffers![index])
    }
  
    addGAEvent({
      event: "select_offer_item",
      offer_name: internalName,
    })
  }

  const handleOpenOffer = (index: number, event: any, type: 'bzb' | 'lotus', id: string) => {
    const name = type === 'bzb' ? 'store' : 'lotus'
    const isClickFavoritedIcon = event?.target?.id === `offers-special-${name}-list-card${index}-favorited-icon`
  
    if (isClickFavoritedIcon) {
      handleFavoritedAction(type, id, index)
    } else {
      handleSelectOfferDetail(type, index, id)
    }
  }

  const handleGetOffers = useCallback(async () => {
    setLoading(true)
    await loadStoreRewardOffers()
    await loadBzbOffers()
    setLoading(false)
  }, [loadBzbOffers, loadStoreRewardOffers])

  // first init
  useEffect(() => {
    if (bzbOffers === undefined && storeRewardOffers === undefined) {
      handleGetOffers()
    }
  }, [bzbOffers, storeRewardOffers, handleGetOffers])

  const renderOffers = () => {
    if (!storeRewardOffers?.length) return <></>

    return (
      <div>
        {storeRewardOffers?.length &&
          storeRewardOffers.map(
            (
              {
                id,
                price,
                offerDetails,
                redemptionStartDate,
                availableInventoryRemaining,
              },
              index
            ) => {
              const expiryDate = getDate(
                offerDetails.acquisitionEndDate!,
                DATE_FORMAT
              )
              const status = getStatus({
                startDate: redemptionStartDate!,
                endDate: expiryDate!,
                point: profile?.points,
                price,
                available: availableInventoryRemaining,
              })

              return (
                <Fragment key={id}>
                  <div
                    id={`offers-special-lotus-list-content${index}`}
                    data-testid={`card-component-${id}`}
                    onClick={(event) => handleOpenOffer(index, event, 'lotus', id)}
                    className={classNames({
                      "pb-[68px]": (index === storeRewardOffers.length - 1) && !bzbOffers?.length,
                    })}
                  >
                    <Card
                      idElement={`offers-special-lotus-list-card${index}`}
                      key={id}
                      company={LOTUS}
                      title={offerDetails.title}
                      fontTitle="text-[16px] leading-6"
                      date={expiryDate}
                      status={status}
                      image={offerDetails.media.url}
                      isFavorited={storeRewardOffers![index].isFavorited}
                      loading={false}
                      startDate={getDate(
                        redemptionStartDate!,
                        "DD MMM BBBB, HH:mm"
                      )}
                    />
                  </div>
                </Fragment>
              )
            }
          )}
      </div>
    )
  }

  const generateDisplayedBZBOffers = () => {
    if (!bzbOffers?.length) return <></>

    return (
      <div>
        <SpecialStoreList
          list={bzbOffers || []}
          loading={false}
          point={profile?.points}
          onClick={(
            id: number | undefined,
            isFavorited: boolean | undefined,
            index: number,
            event: any
          ) => {
            if (id) handleOpenOffer(index, event, 'bzb', id?.toString())
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="wishlist h-[calc(100svh-54px)]">
        {!!loading ? (
          <Loading idElement="wishlist-loading" />
        ) : (
          <>
            {!storeRewardOffers?.length && !bzbOffers?.length ? (
              <EmptyList />
            ) : (
              <>
                {storeRewardOffers && renderOffers()}
                {bzbOffers && generateDisplayedBZBOffers()}
              </>
            )}
          </>
        )}
      </div>
      <div
        className={classNames("fixed inset-x-0 bottom-0", { 
          hidden: !isOpenDetail
        })}
        data-test="footer-wishlist"
      >
        <Footer
          loading={isLoadingProfile}
          displayName={profile?.name || "N/A"}
          cardName="มายโลตัส"
          coin={profile?.points || 0}
        />
      </div>
    </div>
  )
}

export default Wishlist
