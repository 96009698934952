import Loading from './Loading'
import EmptySearch from './EmptySearch'
import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { ErrorResponse } from '../../../../utils/reponseType'
import { PartnerOffers } from '../../../../services/my-coupon-bff/types'
import { getParam } from '../../../../utils/params'
import { getPartnerOffersWithBothLang } from '../../../../services/my-coupon-bff'
import { Error as ErrorPage } from '../../../../common'
import SpecialStoreList from '../specialStoreList'
import { getBZBToken } from '../../../../components/functions'
import { getLocalStorageString } from '../../../../utils/storage'

export interface Props {
  points?: number
  setIsShowingSearchDetailSection: React.Dispatch<React.SetStateAction<boolean>>
  onSelectOfferDetails: (id: number, isFavorited: boolean | undefined, index: number, isSearchSection: boolean, event: any) => void
  idItemSearchListFavorites?: number
  setIdItemSearchListFavorites: React.Dispatch<React.SetStateAction<number | undefined>>
  isAddFavoritesForSearchList: boolean
  lastFavoriteToggleData: {
    id: number | undefined
  }
}

const Search: React.FC<Props> = ({
  points,
  onSelectOfferDetails,
  setIsShowingSearchDetailSection,
  idItemSearchListFavorites,
  setIdItemSearchListFavorites,
  isAddFavoritesForSearchList,
  lastFavoriteToggleData
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState('')
  const [resultKeyword, setResultKeyword] = useState('')
  const [isHideButton, setIsHideButton] = useState<boolean>(true)
  const [showKeywordHistory, setShowKeywordHistory] = useState<boolean>(false)
  const [isShowEmptySearch, setIsShowEmptySearch] = useState<boolean>(false)
  const [keywordHistory, setKeywordHistory] = useState([])
  const inputRef = useRef<HTMLInputElement>(null)

  const [isError, setIsError] = useState<boolean>(false)
  const [error, setError] = useState<ErrorResponse>()
  const [specialStore, setSpecialStore] = useState<PartnerOffers[]>([])

  const handleSearchInput = (text: string) => {
    setSearchText(text)
    text && text.length > 0 ? setIsHideButton(false) : setIsHideButton(true)
  }

  const clearInput = () => {
    setSearchText('')
    setIsShowEmptySearch(false)
    setIsHideButton(true)
  }

  const searchingList = async (text: string) => {
    if (text.replace(/ /g, '').length !== 0) {
      inputRef.current?.blur()
      setShowKeywordHistory(false)
      setIsShowEmptySearch(false)
      setIsLoading(true)

      if (text) {
        try {
          handleKeywordHistory(text)
          const list = await fetchSpecialStoreList()

          if (list) {
            filterBySearch(list, text)
          }
        } catch (e) {
          console.log(e)
        }
      }

      setIsLoading(false)
    }
  }

  const handleKeywordHistory = (keyword: string) => {
    const keywordHistoryStorage = localStorage.getItem('keyword-history')
    let keywordHistory = keywordHistoryStorage ? JSON.parse(keywordHistoryStorage) : []
    const lastedKeyword =
      keywordHistory.length > 0 ? keywordHistory[keywordHistory.length - 1] : undefined
    const currentKeyword = keyword.replace(/ /g, '')

    if (currentKeyword && currentKeyword !== lastedKeyword) {
      keywordHistory = keywordHistory.filter((word: string) => word !== currentKeyword)

      if (keywordHistory?.length === 5) {
        keywordHistory.shift()
      }
      keywordHistory.push(currentKeyword)

      localStorage.setItem('keyword-history', JSON.stringify(keywordHistory))
    }
  }

  const setKeywordList = () => {
    const keywordHistory = localStorage.getItem('keyword-history')
    const newKeyword = keywordHistory ? JSON.parse(keywordHistory) : []

    setKeywordHistory(newKeyword)
  }

  const handleSearchFocus = () => {
    setKeywordList()
    setShowKeywordHistory(true)
  }

  const searchWithValue = (value: string) => {
    handleSearchInput(value)
    searchingList(value)
  }

  const removeHistory = (reverseIndex: number) => {
    const keywordHistory = localStorage.getItem('keyword-history')
    const historyList = keywordHistory ? JSON.parse(keywordHistory) : []
    const actualIndex = historyList.length - 1 - reverseIndex

    if (historyList.length > 0 && actualIndex < historyList.length) {
      historyList.splice(actualIndex, 1)
    }

    localStorage.setItem('keyword-history', JSON.stringify(historyList))
    setKeywordList()
  }

  const fetchSpecialStoreList = useCallback(async () => {
    setError(undefined)
    setIsError(false)
    setSpecialStore([])
    try {
      const token: string = getParam('token') || localStorage?.getItem('token') || ''
      const bzbToken = getBZBToken()
      const response = await getPartnerOffersWithBothLang(token, bzbToken, true)

      if (response) {
        return response as PartnerOffers[]
      }
    } catch (e) {
      console.log(e)
      setError(e as ErrorResponse)
      setIsError(true)
      setIsLoading(false)
    }
  }, [])

  const filterBySearch = (list: PartnerOffers[], value: string) => {
    setSpecialStore([])
    setResultKeyword(value)
    const compareValue = value.replace(/ /g, '')?.toLowerCase()
    if (list && list.length > 0) {
      const filter = [...list]
      const result = filter
        .map((data) => {
          const removeSpaceTh = data?.Name?.replace(/ /g, '')?.toLocaleLowerCase()
          const removeSpaceEn = data?.NameEn?.replace(/ /g, '')?.toLocaleLowerCase()
          if (removeSpaceTh?.includes(compareValue) || removeSpaceEn?.includes(compareValue))
            return data
        })
        .filter((result) => result)

      if (result.length > 0) {
        setSpecialStore(result as PartnerOffers[])
      } else {
        setIsShowEmptySearch(true)
      }
    }
  }

  const updateOffersSpecialStoreForFavorites = useCallback((isAddFav: boolean, id: number) => {
    if (specialStore && specialStore.length > 0) {
      const updatedSpecialStore = specialStore?.map(item => ({
        ...item,
        isFavorited: item.ID === id ? isAddFav : item.isFavorited
      }))
      setSpecialStore(updatedSpecialStore)
    }
  }, [specialStore])

  useEffect(() => {
    if (idItemSearchListFavorites !== undefined) {
      updateOffersSpecialStoreForFavorites(isAddFavoritesForSearchList, idItemSearchListFavorites)
    }
    setIdItemSearchListFavorites(undefined)
  }, [idItemSearchListFavorites, setIdItemSearchListFavorites, isAddFavoritesForSearchList, updateOffersSpecialStoreForFavorites])

  useEffect(() => {
    //about detail only not tricker search page
    const tab = getLocalStorageString('tab')
    if (tab !== 'offer-special-list') {
      const { id } = lastFavoriteToggleData
      if (id !== undefined) {
        const updatedSpecialStore = specialStore?.map(item => ({
          ...item,
          isFavorited: item.ID === id ? !item.isFavorited : item.isFavorited
        }))
        setSpecialStore(updatedSpecialStore)
      }
    }
  }, [lastFavoriteToggleData])

  return (
    <>
      <div
        id="search-input"
        className={classNames(
          'flex flex-row mx-[12px] my-[6px] border-1 rounded-lg border-gray-[#6B6B6B] text-base bg-gray-disable py-[6px] px-[8px]',
          {
            'pointer-events-none': isLoading,
          }
        )}
      >
        <img className="w-[24px]" src="/icons/search-icon.svg" alt="icon" />
        <input
          ref={inputRef}
          id="default-search"
          maxLength={50}
          className={`ml-[8px] mx-[8px] text-base focus:outline-none placeholder-gray-inactive bg-[#E8E8E8] caret-[#00BCB4] leading-[24px] w-full text-gray-primary`}
          placeholder="ค้นหาสิทธิพิเศษ"
          value={searchText}
          onKeyDown={(e) => {
            if (e.key.toLocaleLowerCase() === 'enter') {
              return searchingList(searchText)
            }
          }}
          onChange={(e) => handleSearchInput(e.target.value)}
          enterKeyHint="search"
          autoComplete="off"
          onFocus={handleSearchFocus}
        />
        <img
          className={classNames('w-[18px]', {
            hidden: isHideButton,
          })}
          src="/icons/clear-btn.svg"
          alt="clear"
          onClick={() => clearInput()}
        />
      </div>
      <div
        className={classNames(
          'text-gray-primary mx-[12px] mt-[16px] font-[700] text-[18px] leading-[28px]',
          {
            hidden:
              !resultKeyword || isError || isLoading || isShowEmptySearch || showKeywordHistory,
          }
        )}
      >
        <p className="font-[700]">ผลลัพธ์สำหรับ “{resultKeyword}”</p>
      </div>

      <div
        id="search-result"
        className={classNames('flex flex-col flex-1 overflow-y-auto relative', {
          'overflow-y-hidden': showKeywordHistory,
        })}
      >
        <div
          id="search-keyword-history"
          className={classNames(
            'flex flex-col absolute top-0 left-0 right-0 bottom-0 px-[12px] py-[16px] bg-white text-gray-primary',
            {
              hidden: !showKeywordHistory,
            }
          )}
        >
          <div
            id="search-label"
            className={classNames('font-[700] text-[18px] leading-[28px] text-gray-primary', {
              hidden: keywordHistory.length === 0,
            })}
          >
            <p className="font-[700]">การค้นหาล่าสุด</p>
          </div>

          {[...keywordHistory].reverse().map((value, index) => (
            <div
              id={`search-history-${value}`}
              className={classNames('flex flex-row mx-[4px] my-[10px]')}
              key={`${index}-${value}`}
            >
              <img
                className="w-[24px]"
                alt="icon"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDIuNUM2LjUgMi41IDIgNyAyIDEyLjVDMiAxOCA2LjUgMjIuNSAxMiAyMi41QzE3LjUgMjIuNSAyMiAxOCAyMiAxMi41QzIyIDcgMTcuNSAyLjUgMTIgMi41Wk0xMiAyMC41QzcuNTkgMjAuNSA0IDE2LjkxIDQgMTIuNUM0IDguMDkgNy41OSA0LjUgMTIgNC41QzE2LjQxIDQuNSAyMCA4LjA5IDIwIDEyLjVDMjAgMTYuOTEgMTYuNDEgMjAuNSAxMiAyMC41Wk0xMi41IDguMjVDMTIuNSA3LjgzNTc5IDEyLjE2NDIgNy41IDExLjc1IDcuNUMxMS4zMzU4IDcuNSAxMSA3LjgzNTc5IDExIDguMjVWMTMuNUwxNS41NTM3IDE2LjMwMjJDMTUuOTEwNiAxNi41MjE5IDE2LjM3ODEgMTYuNDEwNiAxNi41OTc4IDE2LjA1MzdDMTYuODE5MiAxNS42OTM4IDE2LjcwNDEgMTUuMjIyNSAxNi4zNDE5IDE1LjAwNTFMMTIuNSAxMi43VjguMjVaIiBmaWxsPSIjNDI0MjQyIi8+Cjwvc3ZnPgo="
              />
              <div
                className={classNames('flex-1 ml-[8px] font-[500] text-[16px] leading-[24px]')}
                onClick={() => searchWithValue(value)}
              >
                <p>{value}</p>
              </div>
              <img
                className="w-[24px]"
                alt="icon"
                onClick={() => removeHistory(index)}
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjcxMDEyIDQuMjEwMTJDMy45OTAyOCAzLjkyOTk2IDQuNDQ0NSAzLjkyOTk2IDQuNzI0NjYgNC4yMTAxMkw5IDguNDg1NDZMMTMuMjc1MyA0LjIxMDEyQzEzLjU1NTUgMy45Mjk5NiAxNC4wMDk3IDMuOTI5OTYgMTQuMjg5OSA0LjIxMDEyQzE0LjU3IDQuNDkwMjggMTQuNTcgNC45NDQ1IDE0LjI4OTkgNS4yMjQ2NkwxMC4wMTQ1IDkuNUwxNC4yODk5IDEzLjc3NTNDMTQuNTcgMTQuMDU1NSAxNC41NyAxNC41MDk3IDE0LjI4OTkgMTQuNzg5OUMxNC4wMDk3IDE1LjA3IDEzLjU1NTUgMTUuMDcgMTMuMjc1MyAxNC43ODk5TDkgMTAuNTE0NUw0LjcyNDY2IDE0Ljc4OTlDNC40NDQ1IDE1LjA3IDMuOTkwMjggMTUuMDcgMy43MTAxMiAxNC43ODk5QzMuNDI5OTYgMTQuNTA5NyAzLjQyOTk2IDE0LjA1NTUgMy43MTAxMiAxMy43NzUzTDcuOTg1NDYgOS41TDMuNzEwMTIgNS4yMjQ2NkMzLjQyOTk2IDQuOTQ0NSAzLjQyOTk2IDQuNDkwMjggMy43MTAxMiA0LjIxMDEyWiIgZmlsbD0iIzQyNDI0MiIvPgo8L3N2Zz4K"
              />
            </div>
          ))}
        </div>

        <div
          id="search-result-list"
          className={classNames('flex flex-col', {
            hidden: isError || isLoading || showKeywordHistory,
            'overflow-y-auto': !isError && isLoading && !isShowEmptySearch,
          })}
        >
          <SpecialStoreList
            list={specialStore}
            loading={false}
            point={points || 0}
            onClick={(id, isFavorited, index, event) => {
              onSelectOfferDetails(id, isFavorited, index, true, event)
              const isFav = event?.target?.id === `offers-special-store-list-card${index}-favorited-icon` ? true : false
              if (!isFav) setIsShowingSearchDetailSection(true)
              if (window) window.parent.postMessage('selected-offer-special-list', '*')
            }}
          />
        </div>

        <div
          id="empty-search"
          className={classNames(
            'flex-1 flex justify-center item-center mb-[125px] h-[100svh] overflow-y-hidden',
            {
              hidden: !isShowEmptySearch,
            }
          )}
        >
          <EmptySearch />
        </div>

        <div
          id="error-search"
          className={classNames(
            'flex-1 flex justify-center item-center mb-[125px] h-[100svh] overflow-y-hidden',
            { hidden: !isError }
          )}
        >
          <ErrorPage
            idElement="offers-special-store-error"
            onClick={() => {
              searchingList(searchText)
            }}
            status={error?.code || 500}
            refCode={error?.refCode || ''}
          />
        </div>

        <div className={classNames('pb-[121px]', { hidden: !isLoading })}>
          <Loading idElement="search-loading" />
        </div>
      </div>
    </>
  )
}

export default Search
